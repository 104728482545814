<app-title-bar [loggedIn]="loggedIn"></app-title-bar>

<mat-card id="login-card" *ngIf="!loggedIn">
  <h2>Einloggen</h2>
  <mat-grid-list cols="2" rowHeight="10rem">
    <form [formGroup]="loginForm" (ngSubmit)="login(loginForm.value)">
      <mat-grid-tile [colspan]="2">
        <mat-form-field>
          <input matInput placeholder="Email" type="email" formControlName="email"/>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2">
        <mat-form-field>
          <input matInput placeholder="Passwort" type="password" formControlName="password"/>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2">
        <button mat-raised-button color="primary" type="submit">Einloggen</button>
      </mat-grid-tile>
    </form>
  </mat-grid-list>
</mat-card>
<div *ngIf="loggedIn">
  <router-outlet></router-outlet>
</div>
