import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Employee } from './../../../../_models/employee';
import { Shift } from './../../../../_models/shift';
import { ShiftassignService } from './../../../../_services/modules/shiftassign.service';
import { EmployeeDataExchangeService } from './../emp-table/emp-dataexchange-service';
import { CheckedShiftsDataSource } from './datasources/checked-shifts-datasource';
import { UncheckedShiftsDataSource } from './datasources/unchecked-shifts-datasource';

@Component({
  selector: 'app-shiftassign-dialog',
  templateUrl: './shiftassign-dialog.component.html',
  styleUrls: ['./shiftassign-dialog.component.css']
})
export class ShiftassignDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  employee: Employee;

  displayedColumns = ['select', 'notation', 'group'];

  assignedShiftsSource: CheckedShiftsDataSource;
  unassignedShiftsSource: UncheckedShiftsDataSource;

  checkedRowsAssigned = [];
  checkedRowsUnassigned = [];

  selectionAssigned = new SelectionModel<Shift>(true, []);
  selectionUnassigned = new SelectionModel<Shift>(true, []);

  constructor(
    private employeeDataService: EmployeeDataExchangeService,
    private shiftassignService: ShiftassignService
  ) { }

  ngOnInit() {
    this.employeeDataService.currentItem.subscribe(item => this.employee = item);
    this.assignedShiftsSource = new CheckedShiftsDataSource();
    this.unassignedShiftsSource = new UncheckedShiftsDataSource();

    this.subscriptions.push(
      this.shiftassignService.getAssignedShifts().subscribe(shifts => {
        this.assignedShiftsSource.refreshData(shifts);
      }),
      this.shiftassignService.getUnassignedShifts().subscribe(shifts => {
        this.unassignedShiftsSource.refreshData(shifts);
      })
    );

    this.shiftassignService.reloadAssignments(this.employee);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllAssignedSelected() {
    const numSelected = this.selectionAssigned.selected.length;
    const numRows = this.assignedShiftsSource.data.length;
    return numSelected === numRows;
  }

  isAllUnassignedSelected() {
    const numSelected = this.selectionUnassigned.selected.length;
    const numRows = this.unassignedShiftsSource.data.length;
    return numSelected === numRows;
  }


  assignRows() {
    this.shiftassignService.assignShiftsToEmployee(this.employee, this.selectionUnassigned.selected);
    this.selectionAssigned.clear();
    this.selectionUnassigned.clear();
  }

  unassignRows() {
    this.shiftassignService.unassignShiftsToEmployee(this.employee, this.selectionAssigned.selected);
    this.selectionAssigned.clear();
    this.selectionUnassigned.clear();
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  assignedMasterToggle() {
    this.isAllAssignedSelected() ?
      this.selectionAssigned.clear() :
      this.assignedShiftsSource.data.forEach(row => this.selectionAssigned.select(row));
  }

  unassignedMasterToggle() {
    this.isAllUnassignedSelected() ?
      this.selectionUnassigned.clear() :
      this.unassignedShiftsSource.data.forEach(row => this.selectionUnassigned.select(row));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

}
