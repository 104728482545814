import { Shift } from './../../../../_models/shift';
import { ShiftDataExchangeService } from './../shift-table/shift-dataexchange-service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ColorpickerDialogComponent } from 'src/app/helper-modules/colorpicker-dialog/colorpicker-dialog.component';

@Component({
  selector: 'app-shift-dialog',
  templateUrl: './shift-dialog.component.html',
  styleUrls: ['./shift-dialog.component.css']
})
export class ShiftDialogComponent implements OnInit {
  shift: Shift;
  shiftForm: FormGroup;
  dialogTitle: string;
  dialogSubmitText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private shiftDataService: ShiftDataExchangeService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ShiftDialogComponent>) { }

  ngOnInit() {
    this.dialogTitle = this.dialogData.title;
    this.dialogSubmitText = this.dialogData.submitText;
    this.shiftDataService.currentItem.subscribe(item => this.shift = item);
    this.shiftForm = this.formBuilder.group(this.shift);
  }

  updateShift(shift: Shift) {
    this.dialogRef.close(shift);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openColorDialog() {
    this.dialog.open(ColorpickerDialogComponent, {
      width: 'fit-content',
      data: {
        currentColor: this.shift.color
      }
    }).afterClosed().subscribe(async data => {
      if (data) {
        this.shift.color = data;
        this.shiftForm.patchValue({ color: data });
      }
    });
  }

}
