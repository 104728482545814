import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-colorpicker-dialog',
  templateUrl: './colorpicker-dialog.component.html',
  styleUrls: ['./colorpicker-dialog.component.css']
})
export class ColorpickerDialogComponent implements OnInit {
  color: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private dialogRef: MatDialogRef<ColorpickerDialogComponent>
  ) { }

  ngOnInit() {
    this.color = this.dialogData.currentColor;
  }

  sendColor() {
    this.dialogRef.close(this.color);
  }

}
