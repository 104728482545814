import { CommonModule, registerLocaleData } from '@angular/common';
import {
  MatIconModule, MatInputModule, MatFormFieldModule, MatGridListModule, MatButtonModule, MatTabsModule,
  MatToolbarModule, MatButtonToggleModule, MatDialogModule, MatTableModule, MatPaginatorModule, MatSortModule,
  MatSelectModule, MatCheckboxModule, MatRadioModule, MatSlideToggleModule
} from '@angular/material';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { AvailabilitiesComponent } from './availabilities.component';
import { NgModule } from '@angular/core';
import { AvailCalendarComponent } from './avail-calendar/avail-calendar.component';
import { AvailDialogComponent } from './avail-dialog/avail-dialog.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeDe from '@angular/common/locales/de';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AvailabilitiesComponent,
    AvailCalendarComponent,
    AvailDialogComponent
  ],

  imports: [
    MatSlideToggleModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatButtonModule,
    MatTabsModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatDialogModule,
    BrowserAnimationsModule,
    FormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule
  ],

  exports: [
    AvailabilitiesComponent
  ],

  entryComponents: [
    AvailDialogComponent
  ],

  providers: [],
  bootstrap: [
    AvailabilitiesComponent
  ]
})

export class AvailabilitiesModule { }
