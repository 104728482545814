<div *ngIf="chosenEmp">
  <h2>
    Verfügbarkeiten im
    {{ currentDate | calendarDate: view + "ViewTitle":locale }} für
    {{ chosenEmp.surname }}
    {{ chosenEmp.name }}
  </h2>
</div>
<div *ngIf="!chosenEmp">
  <h2>Verfügbarkeiten</h2>
</div>

<mat-toolbar class="toolbar time-tracking-header-controls mat-elevation-z2">
  <div *ngIf="!showList">
    <mat-button-toggle-group class="month-selector-button-group">
      <mat-button-toggle
        (click)="monthChanged()"
        mwlCalendarPreviousView
        [(viewDate)]="currentDate"
        [view]="view"
      >
        <mat-icon>navigate_before</mat-icon>
      </mat-button-toggle>
      <h2 class="current-month">
        {{ currentDate | calendarDate: view + "ViewTitle":locale }}
      </h2>
      <mat-button-toggle
        (click)="monthChanged()"
        mwlCalendarNextView
        [(viewDate)]="currentDate"
        [view]="view"
      >
        <mat-icon>navigate_next</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <mat-form-field class="emp-select">
    <mat-label>Mitarbeiter auswählen</mat-label>
    <mat-select [(value)]="chosenEmp" (selectionChange)="empChanged()">
      <div *ngFor="let emp of employees">
        <mat-option [value]="emp">{{ emp.surname }} {{ emp.name }}</mat-option>
      </div>
    </mat-select>
  </mat-form-field>

  <mat-slide-toggle
    class="vacation-toggle"
    [checked]="false"
    color="primary"
    (change)="changeIsVacation($event.checked)"
  >
    Urlaub
  </mat-slide-toggle>
  <mat-slide-toggle *ngIf="!isVacation"
    class="daterange-toggle"
    [checked]="false"
    color="primary"
    (change)="changeDateRange($event.checked)"
  >
    Zeitraum
  </mat-slide-toggle>
</mat-toolbar>

<mat-grid-list cols="5" [rowHeight]="800">
  <mat-grid-tile [colspan]="3">
    <div class="calendar-container mat-elevation-z8">
      <mwl-calendar-month-view
        [weekStartsOn]="1"
        [refresh]="refresh"
        [viewDate]="currentDate"
        [events]="events"
        [locale]="locale"
        (columnHeaderClicked)="clickedColumn = $event.isoDayNumber"
        (dayClicked)="calendarClicked($event.day.date)"
      >
      </mwl-calendar-month-view>
    </div>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="2">
    <app-avail-dialog
      *ngIf="!isVacation && startDate"
      [employee]="chosenEmp"
      [isDateRange]="isDateRange"
      [startDate]="startDate"
      [endDate]="endDate"
    ></app-avail-dialog>
  </mat-grid-tile>
</mat-grid-list>
