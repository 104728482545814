import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.css'],
})
export class ProgressIndicatorComponent implements OnInit {
  constructor() {}

  @Input() value = 100;
  @Input() diameter = 100;
  @Input() mode = 'indeterminate';
  @Input() strokeWidth = 10;
  @Input() isCenter = false;
  @Input() color = 'primary';
  @Input() isBar = false;

  ngOnInit() {
  }
}
