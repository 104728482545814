import { MatTabsModule } from '@angular/material/tabs';
import { ShiftsModule } from './shifts/shifts.module';
import { EmployeesModule } from './employees/employees.module';
import { AdministrationComponent } from './administration.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [AdministrationComponent],
  imports: [
    EmployeesModule,
    ShiftsModule,
    MatTabsModule,
  ],

  exports: [
    AdministrationComponent
  ],

  entryComponents: [
  ],

  providers: [],
  bootstrap: [
    AdministrationComponent
  ]
})
export class AdministrationModule { }
