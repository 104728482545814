<div *ngIf="isBar; then progressBar; else progressSpinner"></div>
<ng-template #progressBar>
  <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>
<ng-template #progressSpinner>
  <div [ngClass]="{ 'center': isCenter }">
    <mat-progress-spinner [diameter]="diameter" [mode]="mode" [color]="color" [strokeWidth]="strokeWidth"
      [value]="value">
    </mat-progress-spinner>
  </div>
</ng-template>
