import { Observable, Subject } from 'rxjs';
import { NotificationComponent } from './../../helper-modules/notification/notification.component';
import { Shift } from './../../_models/shift';
import { Employee } from './../../_models/employee';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShiftassignService {

  assignedShifts = new Subject<Shift[]>();
  unassignedShifts = new Subject<Shift[]>();

  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar) { }


  getAssignedShifts(): Observable<Shift[]> {
    return this.assignedShifts.asObservable();
  }

  getUnassignedShifts(): Observable<Shift[]> {
    return this.unassignedShifts.asObservable();
  }


  reloadAssignments(emp: Employee) {
    // reload assigned shifts
    this.http.get(emp._links.shifts.href)
      .subscribe((response: any) => {
        this.assignedShifts.next(response._embedded.shifts);
      }, error => {
        this.snackbar.openFromComponent(NotificationComponent, {
          duration: 5000,
          data: {
            title: 'Zugeordnete Schichten konnten nicht geladen werden',
            message: error.message,
            type: 'error'
          }
        });
        this.assignedShifts.next([]);
      });

    // reload unassigned shifts
    this.http.get(emp._links.unassignedShifts.href)
      .subscribe((response: any) => {
        this.unassignedShifts.next(response._embedded.shifts);
      }, error => {
        this.snackbar.openFromComponent(NotificationComponent, {
          duration: 5000,
          data: {
            title: 'Bisher nicht zugeordnete Schichten konnten nicht geladen werden',
            message: error.message,
            type: 'error'
          }
        });
        this.unassignedShifts.next([]);
      });
  }

  assignShiftsToEmployee(emp: Employee, shifts: Shift[]) {
    const assignEndpoint = emp._links.self.href + '/assignshifts';
    const shiftLinks = shifts.map(x => x._links.self.href);

    this.http.post(assignEndpoint, shiftLinks).subscribe(() => {
      this.reloadAssignments(emp);
    }, error => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Änderung der zugeordneten Schichten fehlgeschlagen',
          message: error.message,
          type: 'error'
        }
      });
    });
  }

  unassignShiftsToEmployee(emp: Employee, shifts: Shift[]) {
    const unassignEndpoint = emp._links.self.href + '/unassignshifts';
    const shiftLinks = shifts.map(x => x._links.self.href);

    this.http.post(unassignEndpoint, shiftLinks).subscribe(() => {
      this.reloadAssignments(emp);
    }, error => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Änderung der zugeordneten Schichten fehlgeschlagen',
          message: error.message,
          type: 'error'
        }
      });
    });
  }
}
