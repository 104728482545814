import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ColorpickerDialogComponent } from '../../helper-modules/colorpicker-dialog/colorpicker-dialog.component';
import { UserSettings } from './../../_models/usersettings';
import { SettingsService, EntryClickOption, EntryDropOption } from '../../_services/modules/settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  settings: UserSettings;
  settingsForm: FormGroup;

  dropOptions = EntryDropOption;
  clickOptions = EntryClickOption;
  defaultShiftColor = '';

  objectLink: any;
  userLink: any;

  loading = true;

  constructor(
    private settingsService: SettingsService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.settingsService.getSettings().subscribe(settings => {
        this.settings = settings;
        this.settingsForm = this.formBuilder.group(this.settings);
        this.loading = false;
      })
    );
    this.settingsService.reloadSettings();
  }

  submitSettings(settings: UserSettings) {
    this.settingsService.submitSettings(settings);
  }

  openColorDialog() {
    this.dialog.open(ColorpickerDialogComponent, {
      width: 'fit-content',
      data: {
        currentColor: this.settings.defaultShiftColor
      }
    }).afterClosed().subscribe(async data => {
      if (data) {
        this.settings.defaultShiftColor = data;
        this.settingsForm.patchValue({ defaultShiftColor: data });
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }
}

