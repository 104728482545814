<mat-grid-list [rowHeight]="800" [cols]="5">
  <mat-grid-tile [colspan]="3">
    <mat-card>
      <app-plan-calendar></app-plan-calendar>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="2">
    <mat-card>
      <app-empsummary></app-empsummary>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
