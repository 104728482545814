<!-- Heading.....  -->
<h2>Schichten für {{employee.surname}} {{employee.name}}</h2><br /><br />

<mat-grid-list cols="2" rowHeight="2rem">
  <mat-grid-tile [colspan]="1" [rowspan]="2">
    <h3>Zugeordnet</h3>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="1" [rowspan]="2">
    <h3>Nicht zugeordnet</h3>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="1" [rowspan]="20">
    <mat-table #table class="mat-elevation-z8" [dataSource]="assignedShiftsSource">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? assignedMasterToggle() : null"
            [checked]="selectionAssigned.hasValue() && isAllAssignedSelected()"
            [indeterminate]="selectionAssigned.hasValue() && !isAllAssignedSelected()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectionAssigned.toggle(row) : null"
            [checked]="selectionAssigned.isSelected(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- Notation Column -->
      <ng-container matColumnDef="notation">
        <mat-header-cell *matHeaderCellDef> Bezeichnung </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.notation}} </mat-cell>
      </ng-container>

      <!-- Group Column -->
      <ng-container matColumnDef="group">
        <mat-header-cell *matHeaderCellDef> Gruppe </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.shiftgroup}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectionAssigned.toggle(row)">
      </mat-row>
    </mat-table>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="1" [rowspan]="20">
    <mat-table class="mat-elevation-z8" #table [dataSource]="unassignedShiftsSource">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? unassignedMasterToggle() : null"
            [checked]="selectionUnassigned.hasValue() && isAllUnassignedSelected()"
            [indeterminate]="selectionUnassigned.hasValue() && !isAllUnassignedSelected()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectionUnassigned.toggle(row) : null"
            [checked]="selectionUnassigned.isSelected(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- Notation Column -->
      <ng-container matColumnDef="notation">
        <mat-header-cell *matHeaderCellDef> Bezeichnung </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.notation}} </mat-cell>
      </ng-container>

      <!-- Group Column -->
      <ng-container matColumnDef="group">
        <mat-header-cell *matHeaderCellDef> Gruppe </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.shiftgroup}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectionUnassigned.toggle(row)">
      </mat-row>
    </mat-table>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="1" [rowspan]="1">
    <button mat-raised-button color="primary" (click)="unassignRows()">
      >> Auswahl Entfernen >>
    </button>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="1" [rowspan]="1">
    <button mat-raised-button color="primary" (click)="assignRows()">
      << Auswahl Hinzufügen << </button>
  </mat-grid-tile>

</mat-grid-list>
