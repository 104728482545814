import { DayShiftSpecifics } from './../../../../../_models/dayshift';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-dayshift-specifics-dialog',
  templateUrl: './dayshift-specifics-dialog.component.html',
  styleUrls: ['./dayshift-specifics-dialog.component.css']
})
export class DayshiftSpecificsDialogComponent implements OnInit {
  title: string;
  specifics: DayShiftSpecifics;
  specificsForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DayshiftSpecificsDialogComponent>) { }

  ngOnInit() {
    this.specifics = this.dialogData.specifics;
    this.title = this.dialogData.title;
    this.specificsForm = this.formBuilder.group(this.specifics);
  }

  submit(specifics) {
    this.dialogRef.close(specifics);
  }

  closeDialog() {
    this.dialogRef.close();
  }


}
