import { Subscription } from 'rxjs';
import { EmployeeService } from './../../../../_services/modules/employee.service';
import { ShiftassignDialogComponent } from './../shiftassign-dialog/shiftassign-dialog.component';
import { ConfirmationDialogComponent } from './../../../../helper-modules/confirmation-dialog/confirmation-dialog.component';
import { EmployeeDialogComponent } from './../emp-dialog/emp-dialog.component';
import { Employee, EmployeeType, FixedSalaryEmp } from './../../../../_models/employee';
import { EmployeeDataExchangeService } from './emp-dataexchange-service';
import { EmployeeDataSource } from './emp-datasource';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-emp-table',
  templateUrl: './emp-table.component.html',
  styleUrls: ['./emp-table.component.css']
})

export class EmployeeTableComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  displayedColumns: string[] = ['name', 'surname', 'permission', 'edit', 'assign', 'delete'];
  dataSource: EmployeeDataSource;

  constructor(
    private empService: EmployeeService,
    private employeeDataService: EmployeeDataExchangeService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource = new EmployeeDataSource(this.empService);

    this.subscriptions.push(
      this.empService.getEmployees().subscribe(employees => {
        this.dataSource.refreshData(employees);
      })
    );
    this.empService.reloadEmployees();
  }

  addEmployee() {
    const newEmployee: FixedSalaryEmp = {
      empId: 0,
      type: EmployeeType.fixedSalary,
      personellNumber: '',
      username: '',
      password: '',
      name: '',
      surname: '',
      dailyHours: 0,
      daysInWeek: 0,
      monthSalary: 0,
      holidaysPerYear: null,
      permission: 'USER',
      active: true
    };
    this.employeeDataService.changeItem(newEmployee);
    this.dialog.open(EmployeeDialogComponent, {
      width: '800px',
      data: {
        enableTypeBox: true,
        title: 'Neuen Mitarbeiter erstellen',
        submitText: 'Anlegen'
      }
    }).afterClosed().subscribe(data => {
      if (data) {
        this.empService.createEmployee(data);
      }
    });
  }

  editEmployee(employee: Employee) {
    this.employeeDataService.changeItem(employee);
    this.dialog.open(EmployeeDialogComponent, {
      width: '800px',
      data: {
        enableTypeBox: false,
        title: 'Mitarbeiter überarbeiten',
        submitText: 'Update'
      }
    }).afterClosed().subscribe(newData => {
      if (newData) {
        this.empService.updateEmployee(employee, newData);
      }
    });
  }

  deleteEmployee(employee: Employee) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Wirklich löschen?',
        message: 'Mitarbeiter ' + employee.surname + ' ' + employee.name + ' wirklich löschen?'
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.empService.deleteEmployee(employee);
      }
    });
  }

  assignShifts(employee: Employee) {
    this.employeeDataService.changeItem(employee);
    this.dialog.open(ShiftassignDialogComponent, {
      width: '1200px'
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }
}
