<mat-grid-list cols="3" rowheight="90px">
  <div *ngIf="loggedIn">
    <mat-grid-tile [colspan]="1">
      <button mat-icon-button [routerLink]="['planning']">
        <mat-icon>date_range</mat-icon>
      </button>
      <button mat-icon-button [routerLink]="['administration']">
        <mat-icon>transform</mat-icon>
      </button>
      <button mat-icon-button [routerLink]="['settings']">
        <mat-icon>settings</mat-icon>
      </button>
    </mat-grid-tile>
  </div>
  <mat-grid-tile [colspan]="1">

    <h1>Dienstplan Anwendung</h1>

  </mat-grid-tile>
  <mat-grid-tile [colspan]="1">

  </mat-grid-tile>
</mat-grid-list>
