export class Employee {
  empId: number;
  type: EmployeeType;
  personellNumber: string;
  username: string;
  password: string;
  name: string;
  surname: string;
  holidaysPerYear: number;
  permission: string;
  active: boolean;
  // tslint:disable-next-line: variable-name
  _links?: any;
}

export enum EmployeeType {
  shiftBasedSalary = 'shiftBasedSalary',
  empBasedSalary = 'empBasedSalary',
  fixedSalary = 'fixedSalary'
}

// Record type annotation guaranties that
// all the values from the enum are presented in the mapping
export const GermanForEmpType: Record<EmployeeType, string> = {
  [EmployeeType.shiftBasedSalary]: 'Schicht Stundenlohn',
  [EmployeeType.empBasedSalary]: 'Mitarbeiter Stundenlohn',
  [EmployeeType.fixedSalary]: 'Festes Monatsgehalt',
};

// Record type annotation guaranties that
// all the values from the enum are presented in the mapping
export const SpecificFieldsForEmpType: Record<EmployeeType, { [placeholder: string]: string }> = {
  [EmployeeType.shiftBasedSalary]: {
    'Min. Stunden pro Monat': 'minHours',
    'Max. Stunden pro Monat': 'maxHours',
    'Urlaubstag Dauer': 'holidayDuration',
    'Urlaub Stundensatz': 'holidayTimerate'
  },

  [EmployeeType.empBasedSalary]: {
    'Min. Stunden pro Monat': 'minHours',
    'Max. Stunden pro Monat': 'maxHours',
    Stundenlohn: 'timerate',
    'Urlaubstag Dauer': 'holidayDuration'
  },

  [EmployeeType.fixedSalary]: {
    'Stunden pro Tag': 'dailyHours',
    'Tage pro Woche': 'daysInWeek',
    Monatslohn: 'monthSalary',
  }
};

export class ShiftBasedSalaryEmp extends Employee {
  minHours: number;
  maxHours: number;
  holidayDuration: number;
  holidayTimerate: number;
}

export class EmpBasedSalaryEmp extends Employee {
  minHours: number;
  maxHours: number;
  holidayDuration: number;
  timerate: number;
}

export class FixedSalaryEmp extends Employee {
  dailyHours: number;
  daysInWeek: number;
  monthSalary: number;
}
