<div *ngIf="dialogReady">
  <h2>Schichten für {{ date | date: "dd. MMMM" }}</h2>
  <form [formGroup]="selectForm" (ngSubmit)="submit(selectForm.value)">
    <mat-grid-list cols="5" rowHeight="8rem">
      <div *ngFor="let empsForShift of shiftsToCover">
        <mat-grid-tile [colspan]="2">
          <button mat-button type="button" (click)="editDayShiftSpecifics(empsForShift.shift)"
            matTooltip="Klicken, um Schicht für diesen Tag anzupassen">
            <h4>{{ empsForShift.shift.notation }}</h4>
          </button>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3">
          <mat-form-field appearance="fill">
            <mat-label>Mitarbeiter wählen</mat-label>
            <mat-select formControlName="{{ empsForShift.shift.notation }}" [compareWith]="compareEmployees">
              <mat-option>-------</mat-option>
              <mat-option *ngFor="let emp of empsForShift.availEmps" [value]="emp">
                {{ emp.surname }} {{ emp.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
      </div>
      <mat-grid-tile [colspan]="5">
        <button type="submit" mat-raised-button color="primary">
          Übernehmen
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
</div>
