import { Employee } from './../../../../_models/employee';
import { Injectable } from '@angular/core';
import { GenericDataService } from 'src/app/internalServices/genericDataService';

@Injectable({
  providedIn: 'root'
})
export class EmployeeDataExchangeService extends GenericDataService<Employee> {

}
