<div *ngIf="isDateRange">
  <h2>Verfügbarkeiten von {{ startDate | date: "dd. MMMM" }} bis {{ endDate | date: "dd. MMMM" }}</h2>
</div>
<div *ngIf="!isDateRange">
  <h2>Verfügbarkeiten für {{ startDate | date: "dd. MMMM" }}</h2>
</div>

<mat-grid-list cols="2" rowHeight="2rem">
  <mat-grid-tile [colspan]="2" [rowspan]="14">
    <mat-table #table class="mat-elevation-z8" [dataSource]="dataSource">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- Notation Column -->
      <ng-container matColumnDef="notation">
        <mat-header-cell *matHeaderCellDef> Bezeichnung </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.notation }} </mat-cell>
      </ng-container>

      <!-- Group Column -->
      <ng-container matColumnDef="group">
        <mat-header-cell *matHeaderCellDef> Gruppe </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.shiftgroup }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="selection.toggle(row)"
      >
      </mat-row>
    </mat-table>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="2">
    <button mat-raised-button color="primary" type="button" (click)="submit()">
      Übernehmen
    </button>
  </mat-grid-tile>
</mat-grid-list>
