<ng-container *ngIf="isLoadingShiftgroups">
  <app-progress-indicator
    isCenter="true"
    diameter="40"
  ></app-progress-indicator>
</ng-container>
<ng-container *ngIf="!isLoadingShiftgroups">
  <mat-toolbar class="toolbar time-tracking-header-controls mat-elevation-z2">
    <mat-button-toggle-group class="month-selector-button-group">
      <mat-button-toggle
        (click)="reloadMonthData()"
        mwlCalendarPreviousView
        [(viewDate)]="currentDate"
        [view]="view"
      >
        <mat-icon>navigate_before</mat-icon>
      </mat-button-toggle>
      <h2 class="current-month">
        {{ currentDate | calendarDate: view + "ViewTitle":locale }}
      </h2>
      <mat-button-toggle
        (click)="reloadMonthData()"
        mwlCalendarNextView
        [(viewDate)]="currentDate"
        [view]="view"
      >
        <mat-icon>navigate_next</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-select
      id="groupchoose"
      placeholder="Wähle Gruppe"
      [(ngModel)]="selectedShiftgroup"
      (selectionChange)="reloadRosterData()"
    >
      <div *ngFor="let shiftgroup of shiftgroups">
        <mat-option [value]="shiftgroup">{{ shiftgroup }}</mat-option>
      </div>
    </mat-select>
    <button
      id="print-button"
      mat-raised-button
      [disabled]="!selectedShiftgroup || dayShifts.length == 0"
      (click)="printRoster()"
    >
      <mat-icon>print</mat-icon>
    </button>
  </mat-toolbar>

  <mat-grid-list cols="5" [rowHeight]="800">
    <mat-grid-tile [colspan]="3">
      <div class="calendar-container mat-elevation-z8">
        <mwl-calendar-month-view
          [activeDay]="clickedDate"
          [activeDayIsOpen]="activeDayOpen"
          [weekStartsOn]="1"
          [refresh]="refresh"
          [viewDate]="currentDate"
          [events]="events"
          [locale]="locale"
          (columnHeaderClicked)="clickedColumn = $event.isoDayNumber"
          (dayClicked)="calendarClicked($event.day.date)"
        >
        </mwl-calendar-month-view>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="2">
      <app-plan-dialog
        (submitAssignments)="saveDayShiftAssign($event)"
      ></app-plan-dialog>
    </mat-grid-tile>
  </mat-grid-list>
</ng-container>
