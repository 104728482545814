<h1>{{dialogTitle}}</h1>

<mat-grid-list cols="2" rowHeight="6rem">
  <form [formGroup]="shiftForm" (ngSubmit)="updateShift(shiftForm.value)">
    <mat-grid-tile [colspan]="2">
      <mat-form-field>
        <input matInput placeholder="Bezeichnung" formControlName="notation" />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <mat-form-field>
        <input matInput placeholder="Gruppe" formControlName="shiftgroup" />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <mat-form-field>
        <input matInput placeholder="Stundenlohn" formControlName="timerate" />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <mat-form-field>
        <input matInput placeholder="Beginn" formControlName="startTime" />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <mat-form-field>
        <input matInput placeholder="Ende" formControlName="endTime" />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <mat-form-field>
        <input matInput placeholder="Pausendauer (in Minuten)" formControlName="usualBreak" />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <mat-form-field>
        <input matInput placeholder="Standard Schichtfarbe" [style.background]="shift.color" formControlName="color"
          readonly (click)='openColorDialog()' />
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="1">
      <button mat-raised-button color="primary" type="submit">
        {{ dialogSubmitText }}
      </button>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <button mat-raised-button color="accent" type="button" (click)="closeDialog()">
        Abbrechen
      </button>
    </mat-grid-tile>
  </form>
</mat-grid-list>
