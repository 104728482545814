import { Employee, EmployeeType, SpecificFieldsForEmpType, ShiftBasedSalaryEmp, EmpBasedSalaryEmp, FixedSalaryEmp, GermanForEmpType } from './../../../../_models/employee';
import { EmployeeDataExchangeService } from './../emp-table/emp-dataexchange-service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-emp-dialog',
  templateUrl: './emp-dialog.component.html',
  styleUrls: ['./emp-dialog.component.css']
})
export class EmployeeDialogComponent implements OnInit {
  empTypeEnum = EmployeeType;
  empTypeKeys = [];
  selectedEmpType: EmployeeType;
  specificFieldsForEmpType = SpecificFieldsForEmpType;
  germanForEmpType = GermanForEmpType;

  combinedEmpFields: { placeholder: string, formControlName: string }[] = [];
  baseEmpFields: { [placeholder: string]: string } = {
    Personalnummer: 'personellNumber',
    Vorname: 'surname',
    Nachname: 'name',
    'Urlaubstage pro Jahr': 'holidaysPerYear'
  };


  employee: Employee;
  employeeForm: FormGroup;
  dialogTitle: string;
  dialogSubmitText: string;
  enableTypeBox: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private employeeDataService: EmployeeDataExchangeService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EmployeeDialogComponent>) { }

  ngOnInit() {
    this.empTypeKeys = Object.keys(this.empTypeEnum);
    this.dialogTitle = this.dialogData.title;
    this.dialogSubmitText = this.dialogData.submitText;
    this.enableTypeBox = this.dialogData.enableTypeBox;
    this.employeeDataService.currentItem.subscribe(item => this.employee = item);
    this.employeeForm = this.formBuilder.group(this.employee);

    if (this.employee.type === EmployeeType.shiftBasedSalary) {
      this.selectedEmpType = EmployeeType.shiftBasedSalary;
    } else if (this.employee.type === EmployeeType.empBasedSalary) {
      this.selectedEmpType = EmployeeType.empBasedSalary;
    } else {
      this.selectedEmpType = EmployeeType.fixedSalary;
    }
    this.combinedEmpFields = [];
    for (const key of Object.keys(this.baseEmpFields)) {
      this.combinedEmpFields.push({ placeholder: key, formControlName: this.baseEmpFields[key] });
    }
    const specificFields = SpecificFieldsForEmpType[this.selectedEmpType];
    for (const key of Object.keys(specificFields)) {
      this.combinedEmpFields.push({ placeholder: key, formControlName: specificFields[key] });
    }

  }

  empTypeChanged() {
    if (this.selectedEmpType === EmployeeType.fixedSalary) {
      this.employee = {
        empId: this.employee.empId,
        type: EmployeeType.fixedSalary,
        personellNumber: this.employee.personellNumber,
        username: this.employee.username,
        password: this.employee.password,
        name: this.employee.name,
        surname: this.employee.surname,
        holidaysPerYear: this.employee.holidaysPerYear,
        permission: this.employee.permission,
        active: this.employee.active,
        dailyHours: 0,
        daysInWeek: 0,
        monthSalary: 0
      } as FixedSalaryEmp;
    } else if (this.selectedEmpType === EmployeeType.shiftBasedSalary) {
      this.employee = {
        empId: this.employee.empId,
        type: EmployeeType.shiftBasedSalary,
        personellNumber: this.employee.personellNumber,
        username: this.employee.username,
        password: this.employee.password,
        name: this.employee.name,
        surname: this.employee.surname,
        holidaysPerYear: this.employee.holidaysPerYear,
        permission: this.employee.permission,
        active: this.employee.active,
        holidayDuration: 0,
        holidayTimerate: 0,
        maxHours: 0,
        minHours: 0
      } as ShiftBasedSalaryEmp;
    } else if (this.selectedEmpType === EmployeeType.empBasedSalary) {
      this.employee = {
        empId: this.employee.empId,
        type: EmployeeType.empBasedSalary,
        personellNumber: this.employee.personellNumber,
        username: this.employee.username,
        password: this.employee.password,
        name: this.employee.name,
        surname: this.employee.surname,
        holidaysPerYear: this.employee.holidaysPerYear,
        permission: this.employee.permission,
        active: this.employee.active,
        holidayDuration: 0,
        timerate: 0,
        maxHours: 0,
        minHours: 0
      } as EmpBasedSalaryEmp;
      this.employeeForm = this.formBuilder.group(this.employee);
    }
    this.employeeForm = this.formBuilder.group(this.employee);

    this.combinedEmpFields = [];
    for (const key of Object.keys(this.baseEmpFields)) {
      this.combinedEmpFields.push({ placeholder: key, formControlName: this.baseEmpFields[key] });
    }
    const specificFields = SpecificFieldsForEmpType[this.selectedEmpType];
    for (const key of Object.keys(specificFields)) {
      this.combinedEmpFields.push({ placeholder: key, formControlName: specificFields[key] });
    }
  }

  isPlaceholderNeeded() {
    return Object.keys(this.combinedEmpFields).length % 2 === 0;
  }

  updateEmployee(employee) {
    this.dialogRef.close(employee);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
