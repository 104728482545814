import { EmpSummary } from './../../_models/empsummary';
import { MonthData } from './../../_models/monthdata';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintHelperService {

  constructor(
    private http: HttpClient) { }

  public printRoster(monthData: MonthData, shiftGroup: string) {
    window.open(monthData._links.self.href + '/printRoster/' + shiftGroup);
  }

  public printEmpsum(empsum: EmpSummary) {
    window.open(empsum._links.self.href + '/printEmpsum', '_blank');
  }
}
