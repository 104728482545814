import { GlobalConstants } from './../../global/global-constants';
import { NotificationComponent } from './../../helper-modules/notification/notification.component';
import { MatSnackBar } from '@angular/material';
import { of } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { RosterUser } from './../../_models/rosteruser';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RosteruserService {
  currentUser: RosterUser;

  constructor(
    private httpClient: HttpClient,
    private snackbar: MatSnackBar) {

  }

  public setCurrentUser(email: string, password: string): Promise<boolean> {
    return new Promise(success => {
      const mailHash = Md5.hashStr(email);
      this.httpClient.get<any>(GlobalConstants.apiURL + '/rosterusers/' + mailHash).subscribe(response => {

        if (response.password !== password) {
          this.snackbar.openFromComponent(NotificationComponent, {
            duration: 5000,
            data: {
              title: 'Falsche Anmeldedaten',
              message: 'Nutzername oder Passwort nicht gültig',
              type: 'error'
            }
          });
          success(false);
        } else {
          this.currentUser = response;
          success(true);
        }
      }, error => {
        this.snackbar.openFromComponent(NotificationComponent, {
          duration: 5000,
          data: {
            title: 'Nutzerdaten konnten nicht geladen werden',
            message: error.message,
            type: 'error'
          }
        });
        success(false);
      });
    });
  }

  public getCurrentUser(): RosterUser {
    if (this.currentUser) {
      return this.currentUser;
    } else {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Keine Nutzerdaten erreichbar',
          message: 'Es scheint kein Nutzer eingeloggt zu sein',
          type: 'error'
        }
      });
    }
  }
}
