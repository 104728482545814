import { EmpOrdering } from './../../_models/empordering';
import { GlobalConstants } from './../../global/global-constants';
import { RosteruserService } from './../modules/rosteruser.service';
import { Employee } from './../../_models/employee';
import { NotificationComponent } from './../../helper-modules/notification/notification.component';
import { MatSnackBar } from '@angular/material';
import { MonthData } from './../../_models/monthdata';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmpOrderingService {
  textContentType = {
    headers: new HttpHeaders({ 'Content-Type': 'text/uri-list' })
  };

  private existingOrdering: EmpOrdering;

  constructor(
    private http: HttpClient,
    private rosterUserService: RosteruserService,
    private snackbar: MatSnackBar) { }

  public getClaimedEmployees(monthData: MonthData, shiftGroup: string): Promise<{ emps: Employee[], ordering: number[] }> {
    this.existingOrdering = null;
    return new Promise(result => {
      const rostersLink = monthData._links.rosterForShiftgroup.href;
      this.http.get(rostersLink).subscribe(rosterForShiftgroup => {
        if (!rosterForShiftgroup[shiftGroup]) {
          this.snackbar.openFromComponent(NotificationComponent, {
            duration: 5000,
            data: {
              title: 'Druck unmöglich',
              message: 'Für den aktuellen Dienstplan liegen noch keine Daten vor',
              type: 'error'
            }
          });
        } else {
          const claimedEmpsLink = rosterForShiftgroup[shiftGroup]._links.claimedEmps.href;
          this.http.get(claimedEmpsLink).subscribe((emps: any) => {
            // concat all employees
            let allEmps: Employee[] = [];
            if (emps._embedded.fixedSalaryEmps) {
              allEmps = allEmps.concat(emps._embedded.fixedSalaryEmps);
            }
            if (emps._embedded.empBasedSalaryEmps) {
              allEmps = allEmps.concat(emps._embedded.empBasedSalaryEmps);
            }
            if (emps._embedded.shiftBasedSalaryEmps) {
              allEmps = allEmps.concat(emps._embedded.shiftBasedSalaryEmps);
            }

            this.http.get<any>(this.rosterUserService.getCurrentUser()._links.empOrderingForShiftgroup.href)
              .subscribe((orderingForShiftgroup) => {
                if (orderingForShiftgroup[shiftGroup]) {
                  this.existingOrdering = orderingForShiftgroup[shiftGroup];
                  const orderingArray = JSON.parse(orderingForShiftgroup[shiftGroup].ordering);
                  result({ emps: allEmps, ordering: orderingArray });
                } else {
                  result({ emps: allEmps, ordering: [] });
                }
              }, error => {
                console.log('no ordering information found');
                result({ emps: allEmps, ordering: [] });
              });
          });
        }
      });
    });
  }

  persistOrdering(ordering: { shiftgroup: string, ordering: number[] }): Promise<boolean> {
    return new Promise(object => {
      const orderingString = '[' + ordering.ordering.toString() + ']';
      if (!this.existingOrdering) {
        this.http.post(GlobalConstants.apiURL + '/emporderings', { shiftGroup: ordering.shiftgroup, ordering: orderingString })
          .subscribe((response: any) => {
            return this.http.put(
              response._links.rosterUser.href,
              this.rosterUserService.currentUser._links.self.href,
              this.textContentType
            ).subscribe(() => {
              console.log('orderings saved successfully');
              object(true);
            }, error => {
              console.error('orderings could not be saved. Error: ' + error);
            });
          }, error => {
            console.error('orderings could not be saved. Error: ' + error);
          });
      } else {
        this.http.put(this.existingOrdering._links.self.href, { shiftGroup: ordering.shiftgroup, ordering: orderingString })
          .subscribe(() => {
            console.log('ordering updated successfully');
          }, error => {
            console.error('orderings could not be updated. Error: ' + error);
          });
      }
    });
  }

}
