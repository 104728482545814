<mat-grid-list cols="4" rowHeight="6rem">
  <form [formGroup]="dayshiftForm" (ngSubmit)="changeDayshift(dayshiftForm.value)">
    <mat-grid-tile [colspan]="1">
      <mat-form-field>
        <input matInput placeholder="Beginn" formControlName="startTime" />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <mat-form-field>
        <input matInput placeholder="Ende" formControlName="endTime" />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <mat-form-field>
        <input matInput formControlName="shiftBreak" placeholder="Pause" />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <mat-form-field>
        <input matInput formControlName="timerate" placeholder="Stundenlohn" />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="2">
      <button mat-raised-button color="primary" type="submit">
        Übernehmen
      </button>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="2">
      <button mat-raised-button color="accent" type="button" (click)="deleteDayshift(dayshift)">
        Schicht löschen
      </button>
    </mat-grid-tile>
  </form>
</mat-grid-list>
