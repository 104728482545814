import { SortEmpsDialogComponent } from './sort-emps-dialog/sort-emps-dialog.component';
import { PrintHelperService } from './../../../../_services/helper/print-helper.service';
import { ShiftService } from './../../../../_services/modules/shift.service';
import { MonthdataService } from './../../../../_services/modules/monthdata.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CalendarDateFormatter, CalendarEvent, CalendarView } from 'angular-calendar';
import { Subject, Subscription } from 'rxjs';
import { NotificationComponent } from './../../../../helper-modules/notification/notification.component';
import { DayShift, UpdateDayShiftInfo, DayShiftSpecifics } from './../../../../_models/dayshift';
import { Employee } from './../../../../_models/employee';
import { Shift } from './../../../../_models/shift';
import { CustomDateFormatter } from './../../../../_services/helper/custom-date-formatter.provider';

@Component({

  selector: 'app-plan-calendar',
  templateUrl: './plan-calendar.component.html',
  styleUrls: ['./plan-calendar.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class PlanCalendarComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  view: CalendarView = CalendarView.Month;
  activeDayOpen = false;
  locale = 'de';
  events: CalendarEvent[] = [];
  weekStartsOn: 0 = 0;
  refresh: Subject<any> = new Subject();

  currentDate: Date = new Date();
  shiftgroups: string[];
  selectedShiftgroup: string;
  dayShifts: DayShift[] = [];
  clickedDate: Date = new Date();
  clickedColumn: number;

  isLoadingShiftgroups = false;

  constructor(
    private monthdataService: MonthdataService,
    private shiftService: ShiftService,
    private snackbar: MatSnackBar,
    private printHelper: PrintHelperService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.subscriptions.push(
      this.shiftService.getShiftsForShiftgroup().subscribe(shiftsForGroup => {
        this.shiftgroups = Object.keys(shiftsForGroup);
      }),
      this.monthdataService.getDayShiftsCurrentMonth().subscribe(dayshifts => {
        this.dayShifts = dayshifts;
        this.loadPlan();
      })
    );
    this.reloadMonthData();
  }

  calendarClicked(date: Date) {
    if (!this.selectedShiftgroup) {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Ungültige Eingabe',
          message: 'Bitte Schichtgruppe auswählen',
          type: 'error'
        }
      });
      return;
    }
    if (this.currentDate.getMonth() !== date.getMonth()) {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Ungültige Eingabe',
          message: 'Nur der aktuell eingestellte Monat kann bearbeitet werden',
          type: 'error'
        }
      });
      return;
    }
    this.monthdataService.updateClickedRosterDate(date);
    this.activeDayOpen = true;
    this.clickedDate = date;
  }

  reloadRosterData() {
    this.monthdataService.updateClickedRosterDate(null);
    this.monthdataService.reloadRoster(this.selectedShiftgroup);
  }

  reloadMonthData() {
    this.monthdataService.updateClickedRosterDate(null);
    this.selectedShiftgroup = null;
    const yearmonth = (1 + this.currentDate.getMonth()) < 10 ?
      this.currentDate.getFullYear() + '-0' + (1 + this.currentDate.getMonth()) :
      this.currentDate.getFullYear() + '-' + (1 + this.currentDate.getMonth());

    this.monthdataService.setCurrentMonthData(yearmonth);
    this.shiftService.reloadShifts();
  }

  loadPlan() {
    this.activeDayOpen = false;
    this.events = [];
    // sorting dayshifts for better calendar entries
    this.dayShifts.sort((ds1, ds2) => {
      const startHoursDs1 = +ds1.startTime.split(':')[0];
      const startHoursDs2 = +ds2.startTime.split(':')[0];

      if (startHoursDs1 !== startHoursDs2) {
        return startHoursDs1 - startHoursDs2;
      } else {
        const startMinutesDs1 = +ds1.startTime.split(':')[1];
        const startMinutesDs2 = +ds2.startTime.split(':')[1];
        return startMinutesDs1 - startMinutesDs2;
      }

    });
    this.dayShifts.forEach((dayshift: DayShift) => {
      const startHours = dayshift.startTime.split(':')[0];
      const startMinutes = dayshift.startTime.split(':')[1];
      const endHours = dayshift.endTime.split(':')[0];
      const endMinutes = dayshift.endTime.split(':')[1];
      this.events.push({
        title: dayshift.calendarText,
        allDay: false,
        start: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), +dayshift.day, +startHours, + startMinutes),
        end: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), +dayshift.day, +endHours, + endMinutes),
        color: {
          primary: dayshift.color,
          secondary: dayshift.color
        }
      });
    });
    const daysWithEvent: number[] = this.events.map(event => event.start.getDate());
    const daysInMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 0).getDate();

    for (let i = 1; i <= daysInMonth; i++) {
      if (!daysWithEvent.some(element => element === i)) {
        this.events.push({
          title: 'Bisher keine Schicht zugeordnet',
          allDay: true,
          start: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), i, 0, 0),
          color: {
            primary: 'white',
            secondary: 'white'
          }
        });
      }
    }
    this.refresh.next();
  }

  saveDayShiftAssign(data: { shift: Shift, emp: Employee, specifics: DayShiftSpecifics }[]) {
    if (data) {
      const updateDayShiftInfos: UpdateDayShiftInfo[] = [];
      for (const pair of data) {

        const yearmonth = (1 + this.currentDate.getMonth()) < 10 ?
          this.currentDate.getFullYear() + '-0' + (1 + this.currentDate.getMonth()) :
          this.currentDate.getFullYear() + '-' + (1 + this.currentDate.getMonth());

        updateDayShiftInfos.push({
          day: this.clickedDate.getDate(),
          yearmonth,
          employeeLink: pair.emp ? pair.emp._links.self.href : '',
          shiftLink: pair.shift._links.self.href,
          monthDataLink: this.monthdataService.monthData._links.self.href,
          dayShiftSpecifics: pair.specifics ? {
            startTime: pair.specifics.startTime,
            endTime: pair.specifics.endTime,
            shiftBreak: pair.specifics.shiftBreak,
            timerate: pair.specifics.timerate
          } :
            null
        });
      }

      this.monthdataService.updateDayShifts(updateDayShiftInfos);
    }
  }

  printRoster() {
    this.dialog.open(SortEmpsDialogComponent, {
    width: '510px',
    data: {
      monthData: this.monthdataService.monthData,
      shiftgroup: this.selectedShiftgroup
    }
  }).afterClosed().subscribe(data => {
    if (data) {
      this.printHelper.printRoster(this.monthdataService.monthData, this.selectedShiftgroup);
    }
  });
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }
}
