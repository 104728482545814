<div class="table-container mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- empID Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nachname</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <!-- name Column -->
    <ng-container matColumnDef="surname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Vorname</th>
      <td mat-cell *matCellDef="let element">{{ element.surname }}</td>
    </ng-container>

    <!-- surname Column -->
    <ng-container matColumnDef="permission">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Rolle
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.permission }}
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>Bearbeiten</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="editEmployee(element)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="assign">
      <th mat-header-cell *matHeaderCellDef>Schichten</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="assignShifts(element)">
          <mat-icon>format_list_bulleted</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef>Löschen</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="deleteEmployee(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div class="buttons-container">
  <button mat-raised-button color="primary" type="button" (click)="addEmployee()">
    Hinzufügen
  </button>
</div>
