<h1>Einstellungen</h1>
<ng-container *ngIf="loading">
  <app-progress-indicator isCenter="true" diameter="40"></app-progress-indicator>
</ng-container>
<ng-container *ngIf="!loading">
  <mat-grid-list cols="2" rowHeight="10rem">
    <form [formGroup]="settingsForm" (ngSubmit)="submitSettings(settingsForm.value)">
      <mat-grid-tile [colspan]="2">
        <mat-form-field>
          <input matInput placeholder="Region" formControlName="region" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2">
        <mat-form-field>
          <input matInput placeholder="Standard Schichtfarbe" [style.background]="settings.defaultShiftColor"
            formControlName="defaultShiftColor" readonly (click)='openColorDialog()' />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1">
        <button mat-raised-button color="primary" type="submit">Anwenden</button>
      </mat-grid-tile>
    </form>
  </mat-grid-list>
</ng-container>
