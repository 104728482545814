<ng-container *ngIf="loading">
  <app-progress-indicator
    isCenter="true"
    diameter="40"
  ></app-progress-indicator>
</ng-container>

<ng-container *ngIf="!loading">
  <h2>Reihenfolge von links nach rechts</h2>
  <div cdkDropList class="dragdrop-list" (cdkDropListDropped)="drop($event)">
    <div class="dragdrop-box" *ngFor="let emp of employees" cdkDrag>
      {{ emp.surname }} {{ emp.name }}
    </div>
  </div>
  <button mat-raised-button color="primary" (click)="submit()">
    Bestätigen
  </button>
</ng-container>
