<div class="table-container mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- empID Column -->
    <ng-container matColumnDef="empString">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Mitarbeiter</th>
      <td mat-cell *matCellDef="let element">{{ element.empString }}</td>
    </ng-container>

    <!-- name Column -->
    <ng-container matColumnDef="hours">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Stunden</th>
      <td mat-cell *matCellDef="let element">{{ element.hours }}</td>
    </ng-container>

    <!-- surname Column -->
    <ng-container matColumnDef="salary">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Gehalt
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.salary }}
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>Bearbeiten</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="editEmpSum(element)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
