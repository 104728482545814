import { NotificationComponent } from './../../../../helper-modules/notification/notification.component';
import { ConfirmationDialogComponent } from './../../../../helper-modules/confirmation-dialog/confirmation-dialog.component';
import { MonthdataService } from './../../../../_services/modules/monthdata.service';
import { Subscription } from 'rxjs';
import { ShiftassignService } from './../../../../_services/modules/shiftassign.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, LOCALE_ID, OnInit, Input } from '@angular/core';
import { Employee } from './../../../../_models/employee';
import { Shift } from './../../../../_models/shift';
import { EmpShiftDataSource } from './empshift-datasource';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-avail-dialog',
  templateUrl: './avail-dialog.component.html',
  styleUrls: ['./avail-dialog.component.css'],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' }
  ]
})
export class AvailDialogComponent implements OnInit {
  subscriptions: Subscription[] = [];

  @Input() isDateRange: boolean;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() employee: Employee;

  dataSource: EmpShiftDataSource;
  selection = new SelectionModel<Shift>(true, []);
  displayedColumns = ['select', 'notation', 'group'];

  constructor(
    private monthdataService: MonthdataService,
    private shiftAssignService: ShiftassignService,
    public dialog: MatDialog,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    this.dataSource = new EmpShiftDataSource();
    this.subscriptions.push(
      this.shiftAssignService.getAssignedShifts().subscribe(shifts => {
        this.dataSource.refreshData(shifts);
      }),
    );
    this.shiftAssignService.reloadAssignments(this.employee);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  submit() {
    if (this.isDateRange && !this.endDate) {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Ungültige Eingabe',
          message: 'Zeitraum-Auswahl ist aktiviert, aber Enddatum ist nicht gewählt.',
          type: 'error'
        }
      });
      return;
    }

    if (!this.isDateRange) {
      this.monthdataService.updateNonavails(this.startDate, null, this.selection.selected.map(x => x._links.self.href));
    } else {
      if (this.monthdataService.isAvailEntryForDayrange(this.startDate, this.endDate)) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          maxWidth: '400px',
          data: {
            title: 'Einträge überschreiben?',
            message: 'Im gewählten Zeitraum sind bereits Verfügbarkeiten oder Urlaubstage eingetragen. Einträge mit neuen Verfügbarkeiten überschreiben?'
          }
        });
        dialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult) {
            this.monthdataService.updateNonavails(this.startDate, this.endDate, this.selection.selected.map(x => x._links.self.href));
          }
        });
      } else {
        this.monthdataService.updateNonavails(this.startDate, this.endDate, this.selection.selected.map(x => x._links.self.href));
      }
    }
  }
}
