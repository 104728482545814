import { Employee } from './../../../../../_models/employee';
import { EmpOrderingService } from './../../../../../_services/helper/emp-ordering.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-sort-emps-dialog',
  templateUrl: './sort-emps-dialog.component.html',
  styleUrls: ['./sort-emps-dialog.component.css']
})
export class SortEmpsDialogComponent implements OnInit {

  loading = true;
  shiftgroup = '';
  employees: Employee[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private dialogRef: MatDialogRef<SortEmpsDialogComponent>,
    private empOrderingService: EmpOrderingService) { }

  ngOnInit() {
    this.shiftgroup = this.dialogData.shiftgroup;
    this.empOrderingService.getClaimedEmployees(this.dialogData.monthData, this.dialogData.shiftgroup)
      .then((result: { emps: Employee[], ordering: number[] }) => {
        if (result.ordering.length === 0) {
          result.emps.forEach(emp => {
            this.employees.push(emp);
          });
        } else {
          result.ordering.forEach(index => {
            const emp = result.emps.find(emp => emp.empId === index);
            if (emp) {
              this.employees.push(emp);
            }
          });
          const remainingEmps = result.emps.filter(emp => !this.employees.includes(emp));
          remainingEmps.forEach(emp => this.employees.push(emp));
        }
        this.loading = false;
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.employees, event.previousIndex, event.currentIndex);
  }

  submit() {
    const ordering: number[] = [];
    this.employees.forEach(emp => {
      ordering.push(emp.empId);
    });
    this.empOrderingService.persistOrdering({ shiftgroup: this.shiftgroup, ordering })
    this.dialogRef.close(ordering);
  }

}
