import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GenericDataService<ItemType> {

  private itemSource = new BehaviorSubject<ItemType>(null);
  currentItem = this.itemSource.asObservable();

  constructor() { }

  changeItem(item: ItemType) {
    this.itemSource.next(item);
  }

}
