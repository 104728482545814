import { ShiftService } from './../../../../_services/modules/shift.service';
import { Shift } from './../../../../_models/shift';
import { ApiControllerService } from './../../../../_services/api-controller.service';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSort } from '@angular/material';

export class ShiftDataSource extends DataSource<Shift> {
  dataStream = new BehaviorSubject<Shift[]>([]);

  set data(data: Shift[]) {
    this.dataStream.next(data);
  }

  get data(): Shift[] {
    return this.dataStream.value;
  }

  constructor(private shiftService: ShiftService) {
    super();
  }

  refreshData(shifts: Shift[]) {
    this.data = shifts;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<Shift[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      this.dataStream
    ];

    return merge(...dataMutations).pipe(map(() => {
      return [...this.data];
    }));
  }

  disconnect() { }

}
