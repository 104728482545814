import { Employee, ShiftBasedSalaryEmp, EmpBasedSalaryEmp, FixedSalaryEmp, EmployeeType } from './../../../../../_models/employee';
import { PrintHelperService } from './../../../../../_services/helper/print-helper.service';
import { UserSettings } from './../../../../../_models/usersettings';
import { SettingsService } from './../../../../../_services/modules/settings.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { EmpSumDataExchangeService } from './../empsum-dataexchange-service';
import { DayShift } from './../../../../../_models/dayshift';
import { Vacation } from './../../../../../_models/vacation';
import { EmpsumService } from './../../../../../_services/modules/empsum.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

@Component({
  selector: 'app-empsum-dialog',
  templateUrl: './empsum-dialog.component.html',
  styleUrls: ['./empsum-dialog.component.css']
})
export class EmpsumDialogComponent implements OnInit, OnDestroy {
  loading = true;

  subscriptions: Subscription[] = [];

  dialogTitle: string;
  currentEmployee: Employee;
  vacations: Vacation[] = [];
  dayshiftsForTerm: { [term: string]: DayShift[] } = {};
  currentSettings: UserSettings;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private empsumService: EmpsumService,
    private empsumDataService: EmpSumDataExchangeService,
    private settingsService: SettingsService,
    private printHelper: PrintHelperService
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.empsumService.getVacations().subscribe(vacations => {
        this.vacations = vacations;
      }),
      this.empsumService.getDayshiftsForShiftTerm().subscribe(dayshiftsForTerm => {
        this.dayshiftsForTerm = dayshiftsForTerm;
      }),
      this.empsumService.getCurrentEmployee().subscribe(employee => {
        this.currentEmployee = employee;
        this.loading = false;
      })
    );
    this.dialogTitle = this.dialogData.title;
    this.empsumDataService.currentItem.subscribe(item => this.empsumService.reloadDataForEmpsum(item));
  }


  deleteVacation(vacation: Vacation) {
    this.empsumService.deleteVacation(vacation);
  }

  printEmpsum() {
    this.printHelper.printEmpsum(this.empsumService.currentEmpsum);
  }

  getDayshiftPanelDescription(dayshift: DayShift): string {
    if (this.currentEmployee.type === EmployeeType.shiftBasedSalary) {
      return 'Dauer: ' + dayshift.duration + 'h, Gehalt: ' + dayshift.salary + '€';
    } else if (this.currentEmployee.type === EmployeeType.empBasedSalary) {
      const emp = this.currentEmployee as EmpBasedSalaryEmp;
      const dayshiftSalary = dayshift.duration * emp.timerate;
      return 'Dauer: ' + dayshift.duration + 'h, Gehalt: ' + dayshiftSalary + '€';
    } else if (this.currentEmployee.type === EmployeeType.fixedSalary) {
      return 'Dauer: ' + dayshift.duration;
    }
    return '';
  }

  getVacationPanelDescription() {
    if (this.currentEmployee.type === EmployeeType.shiftBasedSalary) {
      const emp = this.currentEmployee as ShiftBasedSalaryEmp;
      return 'Dauer: ' + emp.holidayDuration + 'h, Gehalt: ' + emp.holidayTimerate * emp.holidayDuration + '€';
    } else if (this.currentEmployee.type === EmployeeType.empBasedSalary) {
      const emp = this.currentEmployee as EmpBasedSalaryEmp;
      return 'Dauer: ' + emp.holidayDuration + 'h, Gehalt: ' + emp.timerate * emp.holidayDuration + '€';
    } else if (this.currentEmployee.type === EmployeeType.fixedSalary) {
      const emp = this.currentEmployee as FixedSalaryEmp;
      return 'Dauer: ' + emp.dailyHours + 'h';
    }
    return null;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }
}
