import { ProgressIndicatorComponent } from './progress-indicator.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule, MatProgressBarModule } from '@angular/material';



@NgModule({
  declarations: [
    ProgressIndicatorComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule
  ],
  entryComponents: [],

  exports: [
    ProgressIndicatorComponent
  ]
})
export class ProgressIndicatorModule { }
