import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-availabilities',
  templateUrl: './availabilities.component.html',
  styleUrls: ['./availabilities.component.css']
})
export class AvailabilitiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
