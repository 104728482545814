import { ProgressIndicatorModule } from './../../helper-modules/progress-indicator/progress-indicator.module';
import { NotificationComponent } from './../../helper-modules/notification/notification.component';
import { SettingsComponent } from './settings.component';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule, MatTableModule, MatSortModule, MatSelectModule, MatSnackBarModule } from '@angular/material';
import { ColorPickerModule } from 'ngx-color-picker';
import { ColorpickerDialogComponent } from '../../helper-modules/colorpicker-dialog/colorpicker-dialog.component';


@NgModule({
  declarations: [
    SettingsComponent,
    ColorpickerDialogComponent
  ],

  imports: [
    ProgressIndicatorModule,
    MatSnackBarModule,
    ColorPickerModule,
    FormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatGridListModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule
  ],

  exports: [
    SettingsComponent
  ],

  entryComponents: [
    ColorpickerDialogComponent,
    NotificationComponent
  ],

  providers: [],
  bootstrap: [
    SettingsComponent
  ]
})

export class SettingsModule { }
