import { ConfirmationDialogModule } from './../../../helper-modules/confirmation-dialog/confirmation-dialog.module';
import { ConfirmationDialogComponent } from './../../../helper-modules/confirmation-dialog/confirmation-dialog.component';
import { NotificationComponent } from './../../../helper-modules/notification/notification.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import {
  MatTableModule, MatIconModule, MatInputModule,
  MatPaginatorModule, MatSelectModule, MatSortModule, MatSnackBarModule, MatGridListModule
} from '@angular/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShiftsComponent } from './shifts.component';
import { ShiftTableComponent } from './shift-table/shift-table.component';
import { ShiftDialogComponent } from './shift-dialog/shift-dialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { ColorpickerDialogComponent } from 'src/app/helper-modules/colorpicker-dialog/colorpicker-dialog.component';



@NgModule({
  declarations: [ShiftsComponent, ShiftTableComponent, ShiftDialogComponent],
  imports: [
    ConfirmationDialogModule,
    MatSnackBarModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSortModule,
    BrowserModule,
    ReactiveFormsModule,
    MatGridListModule,
  ],
  exports: [
    ShiftsComponent
  ],
  entryComponents: [
    ColorpickerDialogComponent,
    NotificationComponent,
    ShiftDialogComponent,
    ConfirmationDialogComponent
  ]
})
export class ShiftsModule { }
