import { ShiftsToCoverInfo, ShiftsToCoverInfoIds } from './../../../../_models/shiftsToCoverInfo';
import { ShiftService } from './../../../../_services/modules/shift.service';
import { EmployeeService } from './../../../../_services/modules/employee.service';
import { MonthdataService } from './../../../../_services/modules/monthdata.service';
import { MatDialog } from '@angular/material';
import { DayShift, DayShiftSpecifics } from './../../../../_models/dayshift';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Component, LOCALE_ID, Output, OnDestroy, OnInit, Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Employee } from './../../../../_models/employee';
import { Shift } from './../../../../_models/shift';
import { DayshiftSpecificsDialogComponent } from './dayshift-specifics-dialog/dayshift-specifics-dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-plan-dialog',
  templateUrl: './plan-dialog.component.html',
  styleUrls: ['./plan-dialog.component.css'],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' }
  ]
})
export class PlanDialogComponent implements OnInit, OnDestroy {
  @Output() submitAssignments = new EventEmitter<
    { shift: Shift; emp: Employee; specifics: DayShiftSpecifics }[]
  >();

  dialogReady = false;

  date: Date;
  subscriptions: Subscription[] = [];
  selectForm: FormGroup;

  currentShiftGroup = '';
  allShifts: Shift[];
  groupShifts: Shift[];
  allEmps: Employee[];

  shiftsToCover: ShiftsToCoverInfo[] = [];
  shiftDayShiftPairs: { [shiftNotation: string]: DayShift } = {};
  shiftIDs: { [shiftNotation: string]: Shift } = {};
  specificsIDs: { [shiftNotation: string]: DayShiftSpecifics } = {};

  constructor(
    private formBuilder: FormBuilder,
    private monthdataService: MonthdataService,
    private empService: EmployeeService,
    private shiftService: ShiftService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.specificsIDs = {};

    this.subscriptions.push(
      this.empService.getEmployees().subscribe(emps => {
        this.allEmps = emps;
      }),
      this.shiftService.getShifts().subscribe(shifts => {
        this.allShifts = shifts;
      }),
      this.monthdataService.getClickedRosterDate().subscribe(date => {
        this.specificsIDs = {};
        if (!date) {
          this.dialogReady = false;
          return;
        }
        this.date = date;
        if (this.currentShiftGroup !== this.monthdataService.currentShiftgroup) {
          this.currentShiftGroup = this.monthdataService.currentShiftgroup;
          this.groupShifts = this.allShifts.filter(shift => shift.shiftgroup === this.currentShiftGroup);
        }
        this.monthdataService.reloadShiftsToCover(this.date.getDate());
      }),

      this.monthdataService.getCurrentShiftsToCover().subscribe(shiftsToCover => {
        this.shiftsToCover = [];
        for (const stc of shiftsToCover) {
          const shift = this.groupShifts.find(s => s.shiftId === stc.shiftId);
          const availEmps = this.allEmps.filter(emp => stc.availEmpIds.includes(emp.empId));
          const assignedEmp = this.allEmps.find(emp => emp.empId === stc.assignedEmpId);
          this.shiftsToCover.push({ shift, availEmps, assignedEmp });
        }

        this.selectForm = this.formBuilder.group({});
        for (const entry of this.shiftsToCover) {
          this.shiftIDs[entry.shift.notation] = entry.shift;
          this.selectForm.addControl(entry.shift.notation, new FormControl());
          this.selectForm.get(entry.shift.notation).setValue(entry.assignedEmp);
        }

        this.dialogReady = true;
      }));
  }

  editDayShiftSpecifics(shift: Shift) {
    const dayshift = this.shiftDayShiftPairs[shift.notation];

    const specifics: DayShiftSpecifics = {
      startTime: dayshift ? dayshift.startTime : shift.startTime,
      endTime: dayshift ? dayshift.endTime : shift.endTime,
      shiftBreak: dayshift ? dayshift.shiftBreak : shift.usualBreak,
      timerate: dayshift ? dayshift.timerate : shift.timerate
    };

    this.dialog.open(DayshiftSpecificsDialogComponent, {
      width: '600px',
      data: {
        title: 'Anpassungen für ' + shift.notation,
        specifics
      }
    }).afterClosed().subscribe(data => {
      if (data) {
        this.specificsIDs[shift.notation] = data;
      }
    });
  }

  submit(data) {
    const assignments: { shift: Shift; emp: Employee, specifics: DayShiftSpecifics }[] = [];
    for (const key of Object.keys(data)) {
      assignments.push({
        shift: this.shiftIDs[key],
        emp: data[key],
        specifics: this.specificsIDs[key]
      });
    }
    this.submitAssignments.emit(assignments);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  compareEmployees(e1: Employee, e2: Employee) {
    return e1 && e2 ? e1.empId === e2.empId : false;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
