<div class="table-container mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- notation Column -->
    <ng-container matColumnDef="notation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Bezeichnung</th>
      <td mat-cell *matCellDef="let element">{{ element.notation }}</td>
    </ng-container>

    <!-- group Column -->
    <ng-container matColumnDef="shiftgroup">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Gruppe
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.shiftgroup }}
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>Bearbeiten</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="editShift(element)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef>Löschen</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="deleteShift(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div class="buttons-container">
  <button mat-raised-button color="primary" type="button" (click)="addShift()">
    Hinzufügen
  </button>
</div>
