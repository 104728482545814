import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { ProgressIndicatorComponent } from './helper-modules/progress-indicator/progress-indicator.component';
import { SettingsModule } from './roster-singlepage/settings/settings.module';
import { PlanningModule } from './roster-singlepage/planning/planning.module';
import { AdministrationModule } from './roster-singlepage/administration/administration.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { SettingsComponent } from './roster-singlepage/settings/settings.component';
import { AdministrationComponent } from './roster-singlepage/administration/administration.component';
import { PlanningComponent } from './roster-singlepage/planning/planning.component';
import { NotificationComponent } from './helper-modules/notification/notification.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule, MatButtonModule, MatInputModule, MatCardModule } from '@angular/material';

@NgModule({
  declarations: [
    AppComponent,
    TitleBarComponent,
    NotificationComponent,
  ],
  imports: [
    MatCardModule,
    BrowserModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatGridListModule,
    MatIconModule,
    HttpClientModule,
    AdministrationModule,
    PlanningModule,
    SettingsModule,
    RouterModule.forRoot([
      { path: '', component: PlanningComponent },
      { path: 'planning', component: PlanningComponent },
      { path: 'administration', component: AdministrationComponent },
      { path: 'settings', component: SettingsComponent },
    ])
  ],

  entryComponents: [
  ],

  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
