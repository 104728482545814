<h1>{{ dialogTitle }}</h1>

<mat-grid-list cols="2" rowHeight="6rem">
  <form
    [formGroup]="employeeForm"
    (ngSubmit)="updateEmployee(employeeForm.value)"
  >
    <mat-grid-tile [colspan]="1">
      <mat-form-field>
        <mat-label>Abrechnungstyp</mat-label>
        <mat-select
          [disabled]="!enableTypeBox"
          [(value)]="selectedEmpType"
          (selectionChange)="empTypeChanged()"
        >
          <div *ngFor="let key of empTypeKeys">
            <mat-option [value]="key">{{ germanForEmpType[key] }}</mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" *ngFor="let item of combinedEmpFields">
      <mat-form-field>
        <input
          matInput
          placeholder="{{ item.placeholder }}"
          formControlName="{{ item.formControlName }}"
        />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" *ngIf="isPlaceholderNeeded()"> </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <button mat-raised-button color="primary" type="submit">
        {{ dialogSubmitText }}
      </button>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <button
        mat-raised-button
        color="accent"
        type="button"
        (click)="closeDialog()"
      >
        Abbrechen
      </button>
    </mat-grid-tile>
  </form>
</mat-grid-list>
