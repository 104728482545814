<ng-container *ngIf="loading">
  <app-progress-indicator
    isCenter="true"
    diameter="40"
  ></app-progress-indicator>
</ng-container>
<ng-container *ngIf="!loading">
  <h2>
    {{ dialogTitle
    }}<button id="print-button" mat-raised-button (click)="printEmpsum()">
      <mat-icon>print</mat-icon>
    </button>
  </h2>
  <mat-dialog-content>
    <div *ngFor="let term of dayshiftsForTerm | keyvalue">
      <h4>{{ term.key }}</h4>

      <mat-accordion>
        <mat-expansion-panel *ngFor="let dayshift of term.value">
          <mat-expansion-panel-header>
            <mat-panel-title> {{ dayshift.day }}. </mat-panel-title>
            <mat-panel-description>
              {{ getDayshiftPanelDescription(dayshift) }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-edit-dayshift-dialog
            [dayshift]="dayshift"
          ></app-edit-dayshift-dialog>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <h4 *ngIf="vacations && vacations.length != 0">Urlaub</h4>

    <mat-accordion>
      <mat-expansion-panel *ngFor="let vacation of vacations">
        <mat-expansion-panel-header>
          <mat-panel-title> {{ vacation.day }}. </mat-panel-title>
          <mat-panel-description>
            {{ getVacationPanelDescription() }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <button
          mat-raised-button
          color="accent"
          type="button"
          (click)="deleteVacation(vacation)"
        >
          Urlaubstag löschen
        </button>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-dialog-content>
</ng-container>
