import { GlobalConstants } from './../../global/global-constants';
import { Subject, Observable } from 'rxjs';
import { RosteruserService } from './rosteruser.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationComponent } from '../../helper-modules/notification/notification.component';
import { MatSnackBar } from '@angular/material';
import { UserSettings } from '../../_models/usersettings';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  textContentType = {
    headers: new HttpHeaders({ 'Content-Type': 'text/uri-list' })
  };

  settings = new Subject<UserSettings>();
  settingsExisting = false;

  constructor(
    private http: HttpClient,
    private rosteruserService: RosteruserService,
    private snackbar: MatSnackBar) {
  }

  getSettings(): Observable<UserSettings> {
    return this.settings.asObservable();
  }


  reloadSettings() {
    const settingsLink = this.rosteruserService.getCurrentUser()._links.settings.href;
    this.http.get(settingsLink).subscribe((response: UserSettings) => {
      this.settings.next(response);
      this.settingsExisting = true;
    }, error => {
      this.settings.next({
        region: '',
        defaultShiftColor: ''
      });
      this.settingsExisting = false;
    });
  }

  submitSettings(settings: UserSettings) {
    const settingsLink = this.rosteruserService.getCurrentUser()._links.settings.href;
    if (this.settingsExisting) {
      this.http.get(settingsLink).subscribe((response: UserSettings) => {
        this.http.put(response._links.self.href, settings).subscribe((response2: any) => {
          this.settings.next(response2);
          this.snackbar.openFromComponent(NotificationComponent, {
            duration: 5000,
            data: {
              title: 'Änderungen erfolgreich',
              message: 'Die Einstellungen wurden übernommen',
              type: 'info'
            }
          });
        });
      });
    } else {
      this.http.post(GlobalConstants.apiURL + '/settings', settings).subscribe((response: UserSettings) => {
        this.http.put(
          this.rosteruserService.getCurrentUser()._links.settings.href,
          response._links.self.href,
          this.textContentType
        ).subscribe(() => {
          this.settings.next(response);
          this.snackbar.openFromComponent(NotificationComponent, {
            duration: 5000,
            data: {
              title: 'Änderungen erfolgreich',
              message: 'Die Einstellungen wurden übernommen',
              type: 'info'
            }
          });
        });
      });
    }
  }
}

export enum EntryClickOption {
  EDIT = 'Bearbeiten',
  DELETE = 'Löschen',
  ASK = 'Immer fragen'
}

export enum EntryDropOption {
  MOVE = 'Verschieben',
  COPY = 'Duplizieren',
  ASK = 'Immer fragen'
}
