import { GlobalConstants } from './../../global/global-constants';
import { ShiftsToCoverInfoIds } from './../../_models/shiftsToCoverInfo';
import { EmpSummary } from './../../_models/empsummary';
import { Employee } from './../../_models/employee';
import { UpdateDayShiftInfo } from './../../_models/dayshift';
import { NotificationComponent } from './../../helper-modules/notification/notification.component';
import { Availability } from './../../_models/availability';
import { Vacation } from './../../_models/vacation';
import { Shift } from './../../_models/shift';
import { MatSnackBar } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { RosteruserService } from './rosteruser.service';
import { Injectable } from '@angular/core';
import { MonthData } from '../../_models/monthdata';
import { DayShift } from 'src/app/_models/dayshift';

@Injectable({
  providedIn: 'root'
})
export class MonthdataService {
  textContentType = {
    headers: new HttpHeaders({ 'Content-Type': 'text/uri-list' })
  };

  monthdataBusy = false;
  monthData: MonthData;
  currentYearmonth: string;
  monthAvailsForEmpId: Map<number, Availability> = new Map();

  // for availability calendar:
  currentEmployee: Employee;
  currentNonAvailShiftsForDay: Map<number, Shift[]> = new Map();
  currentVacationsForDay: Map<number, Vacation> = new Map();
  currentAvailEvents = new Subject<{
    nonavailsForDay: Map<number, Shift[]>,
    vacationsForDay: Map<number, Vacation>
  }>();

  // for roster:
  clickedRosterDate = new Subject<Date>();
  currentShiftgroup: string;
  currentGroupShifts: Shift[] = [];
  dayShiftsCurrentMonth = new Subject<DayShift[]>();

  // for plan dialog
  currentShiftIDs = new Subject<{ [shiftNotation: string]: Shift }>();
  currentShiftsToCover = new Subject<ShiftsToCoverInfoIds[]>();

  // for employee summaries
  monthEmpsums = new Subject<EmpSummary[]>();
  monthEmpsumsForEmpId: Map<number, EmpSummary>;

  constructor(
    private http: HttpClient,
    private rosteruserService: RosteruserService,
    private snackbar: MatSnackBar) { }

  public getClickedRosterDate(): Observable<Date> {
    return this.clickedRosterDate.asObservable();
  }

  public getCurrentNonAvailsShiftsForDay(): Map<number, Shift[]> {
    return this.currentNonAvailShiftsForDay;
  }

  public getCurrentVacationsForDay(): Map<number, Vacation> {
    return this.currentVacationsForDay;
  }

  public getCurrentAvailEvents(): Observable<{
    nonavailsForDay: Map<number, Shift[]>,
    vacationsForDay: Map<number, Vacation>
  }> {
    return this.currentAvailEvents.asObservable();
  }

  public getDayShiftsCurrentMonth(): Observable<DayShift[]> {
    return this.dayShiftsCurrentMonth.asObservable();
  }

  public getEmpsums(): Observable<EmpSummary[]> {
    return this.monthEmpsums.asObservable();
  }

  public getCurrentShiftIDs(): Observable<{ [shiftNotation: string]: Shift }> {
    return this.currentShiftIDs.asObservable();
  }

  public getCurrentShiftsToCover(): Observable<ShiftsToCoverInfoIds[]> {
    return this.currentShiftsToCover.asObservable();
  }

  public updateClickedRosterDate(date: Date) {
    this.clickedRosterDate.next(date);
  }

  public async reloadShiftsToCover(day: number) {
    const shiftsToCover: ShiftsToCoverInfoIds[] =
      await this.http.get<any>
        (this.monthData._links.self.href + '/getRosterDayInfo/' + this.currentShiftgroup + '/' + day).toPromise();

    this.currentShiftsToCover.next(shiftsToCover);
  }

  public setCurrentAvailEmpId(emp: Employee) {
    this.currentEmployee = emp;
    this.loadNonAvailsAndVacationsFromAvail();
  }

  public setCurrentMonthData(yearmonth: string) {
    if (this.monthdataBusy) {
      return;
    }
    this.monthdataBusy = true;
    this.monthAvailsForEmpId = new Map();
    this.currentNonAvailShiftsForDay = new Map();
    this.currentVacationsForDay = new Map();

    this.currentYearmonth = yearmonth;
    const monthdataLink = this.rosteruserService.getCurrentUser()._links.monthDataForYearmonth.href;
    this.http.get(monthdataLink).subscribe(getResponse => {
      if (getResponse && getResponse[yearmonth]) {
        this.monthData = getResponse[yearmonth];
        this.monthdataBusy = false;
        this.reloadEmpsums();
      } else {
        this.http.post<MonthData>(GlobalConstants.apiURL + '/monthdata', {
          monthdata_id: 0,
          dataYearmonth: yearmonth
        }).subscribe(createResponse => {
          this.http.put(
            createResponse._links.rosterUser.href,
            this.rosteruserService.currentUser._links.self.href,
            this.textContentType
          ).subscribe(() => {
            this.monthData = createResponse;
            this.monthdataBusy = false;
            this.reloadEmpsums();
          });
        });
      }
    });
  }

  public reloadMonthData() {
    this.setCurrentMonthData(this.currentYearmonth);
    this.reloadRoster(this.currentShiftgroup);
  }

  isAvailEntryForDayrange(startDate: Date, endDate: Date) {
    for (let i = startDate.getDate(); i <= endDate.getDate(); i++) {
      if (this.currentVacationsForDay[i] || this.currentNonAvailShiftsForDay[i]) {
        return true;
      }
    }
    return false;
  }

  updateNonavails(startDate: Date, endDate: Date, linkList: string[]) {
    let availEndpoint = this.monthAvailsForEmpId[this.currentEmployee.empId]._links.self.href + '/updatenonavail/' + startDate.getDate();
    availEndpoint += endDate ? '/' + endDate.getDate() : '/' + startDate.getDate();

    this.http.post(availEndpoint, linkList)
      .subscribe(() => {
        this.loadNonAvailsAndVacationsFromAvail();
      }, error => {
        this.snackbar.openFromComponent(NotificationComponent, {
          duration: 5000,
          data: {
            title: 'Verfügbarkeitsdaten konnten nicht geändert werden',
            message: error.message,
            type: 'error'
          }
        });
      });
  }

  switchVacation(date: Date) {
    if (this.currentVacationsForDay[date.getDate()]) {
      // delete vacation
      this.http.delete(this.currentVacationsForDay[date.getDate()]._links.self.href).subscribe(() => {
        this.snackbar.openFromComponent(NotificationComponent, {
          duration: 5000,
          data: {
            title: 'Urlaubstag entfernt',
            type: 'info'
          }
        });
        this.loadNonAvailsAndVacationsFromAvail();
        this.reloadEmpsums();
      }, error => {
      });
    } else {
      // create new vacation
      const newVacation: Vacation = {
        day: date.getDate()
      };
      this.http.post(
        GlobalConstants.apiURL + '/vacations',
        newVacation
      ).subscribe((createResponse: any) => {
        this.currentVacationsForDay[date.getDate()] = createResponse;
        this.http.put(
          createResponse._links.availability.href,
          this.monthAvailsForEmpId[this.currentEmployee.empId]._links.self.href,
          this.textContentType
        ).subscribe(() => {
          this.http.post(
            GlobalConstants.apiURL + '/empsummaries/applyVacationToEmpsum',
            {
              monthDataLink: this.monthData._links.self.href,
              vacationLink: createResponse._links.self.href,
              employeeLink: this.currentEmployee._links.self.href
            }
          ).subscribe(() => {
            this.snackbar.openFromComponent(NotificationComponent, {
              duration: 5000,
              data: {
                title: 'Urlaubstag hinzugefügt',
                type: 'info'
              }
            });
            this.reloadEmpsums();
            this.loadNonAvailsAndVacationsFromAvail();
          }, error => {
            window.alert(JSON.stringify(error));
          });
        }, error => {
        });
      }, error => {
      });
    }
  }

  private loadNonAvailsAndVacationsFromAvail() {
    if (!this.currentEmployee) {
      return;
    }
    if (this.monthAvailsForEmpId[this.currentEmployee.empId]) {
      const avail = this.monthAvailsForEmpId[this.currentEmployee.empId];
      this.loadDataFromAvail(avail);
    } else {
      this.http.get(this.monthData._links.availsForEmpId.href).subscribe((availsForEmpId: any) => {
        if (availsForEmpId) {
          this.monthAvailsForEmpId = availsForEmpId;
        }
        if (availsForEmpId[this.currentEmployee.empId]) {
          this.loadDataFromAvail(this.monthAvailsForEmpId[this.currentEmployee.empId]);
        } else {
          this.http.post<Availability>(GlobalConstants.apiURL + '/availabilities', {
            yearmonth: this.currentYearmonth
          }).subscribe(createResponse => {
            this.http.put(
              createResponse._links.monthData.href,
              this.monthData._links.self.href,
              this.textContentType
            ).subscribe(() => {
              this.http.put(
                createResponse._links.employee.href,
                this.currentEmployee._links.self.href,
                this.textContentType
              ).subscribe(() => {
                this.monthAvailsForEmpId[this.currentEmployee.empId] = createResponse;
                const avail = this.monthAvailsForEmpId[this.currentEmployee.empId];
                this.loadDataFromAvail(avail);
              });
            });
          });
        }
      });
    }
  }

  private reloadEmpsums() {
    this.http.get(this.monthData._links.empSumsForEmpId.href).subscribe((empSumsForEmpId: any) => {
      if (empSumsForEmpId) {
        this.monthEmpsumsForEmpId = empSumsForEmpId;
      }
    });
    this.http.get(this.monthData._links.empSummaries.href).subscribe((empSummaries: any) => {
      if (empSummaries && empSummaries._embedded && empSummaries._embedded.empsummaries) {
        this.monthEmpsums.next(empSummaries._embedded.empsummaries);
      }
    });
  }

  public async reloadRoster(shiftGroup: string) {
    this.currentShiftgroup = shiftGroup;

    const shiftsLink = this.rosteruserService.getCurrentUser()._links.shifts.href;
    const response: any = await this.http.get(shiftsLink).toPromise();
    const shifts: Shift[] = response._embedded.shifts;
    this.currentGroupShifts = [];
    shifts.forEach((shift) => {
      if (shift.shiftgroup === this.currentShiftgroup) {
        this.currentGroupShifts.push(shift);
      }
    });

    const rostersLink = this.monthData._links.rosterForShiftgroup.href;
    this.http.get(rostersLink).subscribe(rosterForShiftgroup => {
      if (!rosterForShiftgroup[this.currentShiftgroup]) {
        this.dayShiftsCurrentMonth.next([]);
      } else {
        const dayshiftsLink = rosterForShiftgroup[this.currentShiftgroup]._links.dayShifts.href;
        this.http.get(dayshiftsLink).subscribe((dayshifts: any) => {
          this.dayShiftsCurrentMonth.next(dayshifts._embedded.dayshifts);
        });

      }
    }, error => {
      this.dayShiftsCurrentMonth.next(null);
    });
  }

  public updateDayShifts(infos: UpdateDayShiftInfo[]) {
    return this.http.post(GlobalConstants.apiURL + '/dayshifts/updateDayshifts', infos).subscribe(() => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Erfolgreich gespeichert',
          message: 'Die Schichten wurden erfolgreich übernommen',
          type: 'info'
        }
      });
      this.reloadRoster(this.currentShiftgroup);
      this.reloadEmpsums();
    });
  }

  private loadDataFromAvail(avail: any) {
    this.http.get(avail._links.nonAvailShiftsForDay.href).subscribe(nonavailsForDay => {
      const nonAvails: Map<number, Shift[]> = new Map();
      Object.keys(nonavailsForDay).forEach(key => {
        const newKey = key.split('_')[0];
        if (nonAvails[newKey]) {
          const shift = nonavailsForDay[key];
          nonAvails[newKey].push(shift);
        } else {
          const shift = nonavailsForDay[key];
          nonAvails[newKey] = [shift];
        }
      });
      this.http.get(avail._links.vacations.href).subscribe((vacationsForDay: any) => {
        this.currentVacationsForDay = vacationsForDay;
        this.currentNonAvailShiftsForDay = nonAvails;

        this.currentAvailEvents.next({
          nonavailsForDay: nonAvails,
          vacationsForDay
        });
      });
    });
  }
}
