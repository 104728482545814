import { EmpSummary } from './../../../../_models/empsummary';
import { Injectable } from '@angular/core';
import { GenericDataService } from 'src/app/internalServices/genericDataService';

@Injectable({
  providedIn: 'root'
})
export class EmpSumDataExchangeService extends GenericDataService<EmpSummary> {

}
