import { EmpsumDialogComponent } from './empsum-dialog/empsum-dialog.component';
import { EmpSummary } from './../../../../_models/empsummary';
import { EmpSumDataExchangeService } from './empsum-dataexchange-service';
import { MatDialog } from '@angular/material';
import { MonthdataService } from './../../../../_services/modules/monthdata.service';
import { EmpSumDataSource } from './empsum-datasource';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-empsummary',
  templateUrl: './empsummary.component.html',
  styleUrls: ['./empsummary.component.css']
})
export class EmpsummaryComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  displayedColumns: string[] = ['empString', 'hours', 'salary', 'edit'];
  dataSource: EmpSumDataSource;

  constructor(
    private monthdataService: MonthdataService,
    private empsumDataService: EmpSumDataExchangeService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource = new EmpSumDataSource();

    this.subscriptions.push(
      this.monthdataService.getEmpsums().subscribe(empsums => {
        this.dataSource.refreshData(empsums);
      })
    );
  }

  editEmpSum(empsum: EmpSummary) {
    this.empsumDataService.changeItem(empsum);
    this.dialog.open(EmpsumDialogComponent, {
      width: '800px',
      minHeight: '600px',
      maxHeight: '90%',
      data: {
        title: 'Zusammenfassung ' + empsum.empString,
      }
    }).afterClosed().subscribe(newData => {
      this.monthdataService.reloadMonthData();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }
}
