import { ProgressIndicatorModule } from './../../../helper-modules/progress-indicator/progress-indicator.module';
import { PlanComponent } from './plan.component';
import { NgModule } from '@angular/core';
import { PlanDialogComponent } from './plan-dialog/plan-dialog.component';
import { PlanCalendarComponent } from './plan-calendar/plan-calendar.component';
import {
  MatIconModule, MatInputModule, MatFormFieldModule, MatGridListModule,
  MatButtonModule, MatTabsModule, MatToolbarModule, MatButtonToggleModule,
  MatDialogModule, MatTableModule, MatPaginatorModule, MatSortModule, MatSlideToggleModule,
  MatSelectModule, MatCardModule, MatTooltipModule, MatExpansionModule, MatDividerModule
} from '@angular/material';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EmpsummaryComponent } from './empsummary/empsummary.component';
import { DayshiftSpecificsDialogComponent } from './plan-dialog/dayshift-specifics-dialog/dayshift-specifics-dialog.component';
import { EmpsumDialogComponent } from './empsummary/empsum-dialog/empsum-dialog.component';
import { EditDayshiftDialogComponent } from './empsummary/empsum-dialog/edit-dayshift-dialog/edit-dayshift-dialog.component';
import { SortEmpsDialogComponent } from './plan-calendar/sort-emps-dialog/sort-emps-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    PlanComponent,
    PlanDialogComponent,
    PlanCalendarComponent,
    EmpsummaryComponent,
    DayshiftSpecificsDialogComponent,
    EmpsumDialogComponent,
    EditDayshiftDialogComponent,
    SortEmpsDialogComponent,
  ],

  imports: [
    DragDropModule,
    MatDividerModule,
    MatExpansionModule,
    MatCardModule,
    ProgressIndicatorModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatButtonModule,
    MatTabsModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatDialogModule,
    BrowserAnimationsModule,
    FormsModule,
    MatTooltipModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule
  ],

  exports: [
    PlanComponent
  ],

  entryComponents: [
    DayshiftSpecificsDialogComponent,
    EmpsumDialogComponent,
    SortEmpsDialogComponent
  ],

  providers: [],
  bootstrap: [
    PlanComponent
  ]
})

export class PlanModule { }
