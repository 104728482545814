import { EmpsumService } from './../../../../../../_services/modules/empsum.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DayShift } from './../../../../../../_models/dayshift';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-dayshift-dialog',
  templateUrl: './edit-dayshift-dialog.component.html',
  styleUrls: ['./edit-dayshift-dialog.component.css']
})
export class EditDayshiftDialogComponent implements OnInit {
  @Input() dayshift: DayShift;
  dayshiftForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private empsumService: EmpsumService
  ) { }

  ngOnInit() {
    this.dayshiftForm = this.formBuilder.group(this.dayshift);
  }


  changeDayshift(dayshift) {
    this.empsumService.updateDayshift(dayshift);
  }

  deleteDayshift(dayshift: DayShift) {
    this.empsumService.deleteDayshift(dayshift);
  }

}
