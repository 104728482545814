import { ConfirmationDialogModule } from './../../../helper-modules/confirmation-dialog/confirmation-dialog.module';
import { ConfirmationDialogComponent } from './../../../helper-modules/confirmation-dialog/confirmation-dialog.component';
import { EmployeeDialogComponent } from './emp-dialog/emp-dialog.component';
import { EmployeeTableComponent } from './emp-table/emp-table.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import {
  MatTableModule, MatIconModule, MatInputModule, MatSelectModule, MatPaginatorModule, MatSortModule, MatSnackBarModule,
  MatGridListModule,
  MatCheckboxModule
} from '@angular/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { EmployeesComponent } from './employees.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ShiftassignDialogComponent } from './shiftassign-dialog/shiftassign-dialog.component';



@NgModule({
  declarations: [EmployeesComponent, EmployeeTableComponent, EmployeeDialogComponent, ShiftassignDialogComponent],
  imports: [
    ConfirmationDialogModule,
    MatSnackBarModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSortModule,
    BrowserModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCheckboxModule,
  ],
  exports: [
    EmployeesComponent
  ],
  entryComponents: [
    EmployeeDialogComponent,
    ConfirmationDialogComponent,
    ShiftassignDialogComponent
  ]
})
export class EmployeesModule { }
