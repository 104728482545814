import { AvailabilitiesModule } from './availabilities/availabilities.module';
import { NgModule } from '@angular/core';
import { PlanningComponent } from './planning.component';
import { PlanModule } from './plan/plan.module';
import {
  MatTabsModule
} from '@angular/material';

@NgModule({
  declarations: [PlanningComponent],
  imports: [
    AvailabilitiesModule,
    PlanModule,
    MatTabsModule
  ],
  exports: [
    PlanningComponent
  ],

  entryComponents: [
  ],

  providers: [],
  bootstrap: [
    PlanningComponent
  ]
})
export class PlanningModule { }
