import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatDialogModule } from '@angular/material';



@NgModule({
  declarations: [
    ConfirmationDialogComponent
  ],
  imports: [
    MatButtonModule,
    MatDialogModule,
    CommonModule
  ],
  entryComponents: [ConfirmationDialogComponent]
})
export class ConfirmationDialogModule { }
