import { UserSettings } from './../../../../_models/usersettings';
import { ShiftService } from './../../../../_services/modules/shift.service';
import { ConfirmationDialogComponent } from './../../../../helper-modules/confirmation-dialog/confirmation-dialog.component';
import { SettingsService } from '../../../../_services/modules/settings.service';
import { Shift } from './../../../../_models/shift';
import { ShiftDataExchangeService } from './shift-dataexchange-service';
import { ShiftDataSource } from './shift-datasource';
import { ShiftDialogComponent } from './../shift-dialog/shift-dialog.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shift-table',
  templateUrl: './shift-table.component.html',
  styleUrls: ['./shift-table.component.css']
})

export class ShiftTableComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  displayedColumns: string[] = ['notation', 'shiftgroup', 'edit', 'delete'];
  dataSource: ShiftDataSource;
  currentSettings: UserSettings;

  constructor(
    private settingsService: SettingsService,
    private shiftService: ShiftService,
    private shiftDataService: ShiftDataExchangeService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {

    this.subscriptions.push(
      this.settingsService.getSettings().subscribe(settings => {
        this.currentSettings = settings;
      }),
      this.shiftService.getShifts().subscribe(shifts => {
        this.dataSource.refreshData(shifts);
      })
    );
    this.settingsService.reloadSettings();
    this.shiftService.reloadShifts();
    this.dataSource = new ShiftDataSource(this.shiftService);
  }

  addShift() {
    const settings = this.settingsService.getSettings();
    const newShift: Shift = {
      shiftId: 0,
      notation: '',
      shiftgroup: '',
      empPerDay: null,
      timerate: null,
      startTime: '',
      endTime: '',
      usualBreak: null,
      color: this.currentSettings.defaultShiftColor
    };
    this.shiftDataService.changeItem(newShift);
    this.dialog.open(ShiftDialogComponent, {
      width: '800px',
      data: {
        title: 'Neue Schicht erstellen',
        submitText: 'Anlegen'
      }
    }).afterClosed().subscribe(data => {
      if (data) {
        this.shiftService.createShift(data);
      }
    });
  }

  editShift(shift: Shift) {
    this.shiftDataService.changeItem(shift);
    this.dialog.open(ShiftDialogComponent, {
      width: '800px',
      data: {
        title: 'Schicht überarbeiten',
        submitText: 'Update'
      }
    }).afterClosed().subscribe(async newData => {
      if (newData) {
        this.shiftService.updateShift(shift, newData);
      }
    });
  }

  async deleteShift(shift: Shift) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Wirklich löschen?',
        message: 'Schicht ' + shift.notation + ' (' + shift.shiftgroup + ') wirklich löschen?'
      }
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {
      if (dialogResult) {
        this.shiftService.deleteShift(shift);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }
}
