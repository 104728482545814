import { GlobalConstants } from './../../global/global-constants';
import { Subject, Observable } from 'rxjs';
import { NotificationComponent } from './../../helper-modules/notification/notification.component';
import { MatSnackBar } from '@angular/material';
import { RosteruserService } from './rosteruser.service';
import { Employee, FixedSalaryEmp, ShiftBasedSalaryEmp, EmployeeType } from './../../_models/employee';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  textContentType = {
    headers: new HttpHeaders({ 'Content-Type': 'text/uri-list' })
  };

  employees = new Subject<Employee[]>();

  constructor(
    private http: HttpClient,
    private rosteruserService: RosteruserService,
    private snackbar: MatSnackBar) { }

  getEmployees(): Observable<Employee[]> {
    return this.employees.asObservable();
  }

  reloadEmployees() {
    const employeesLink = this.rosteruserService.getCurrentUser()._links.employees.href;
    return this.http.get(employeesLink).subscribe((response: any) => {
      let allEmps: Employee[] = [];
      if (response._embedded.fixedSalaryEmps) {
        allEmps = allEmps.concat(response._embedded.fixedSalaryEmps);
      }
      if (response._embedded.empBasedSalaryEmps) {
        allEmps = allEmps.concat(response._embedded.empBasedSalaryEmps);
      }
      if (response._embedded.shiftBasedSalaryEmps) {
        allEmps = allEmps.concat(response._embedded.shiftBasedSalaryEmps);
      }
      this.employees.next(allEmps);
    }, error => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Mitarbeiter konnten nicht geladen werden',
          message: error.message,
          type: 'error'
        }
      });
      this.employees.next([]);
    });
  }

  createEmployee(employee: Employee) {
    let empLink = '';
    if (employee.type === EmployeeType.fixedSalary) {
      empLink = GlobalConstants.apiURL + '/fixedSalaryEmps';
    } else if (employee.type === EmployeeType.shiftBasedSalary) {
      empLink = GlobalConstants.apiURL + '/shiftBasedSalaryEmps';
    } else {
      empLink = GlobalConstants.apiURL + '/empBasedSalaryEmps';
    }

    this.http.post(empLink, employee).subscribe((response: any) => {
      return this.http.put(
        response._links.rosterUser.href,
        this.rosteruserService.currentUser._links.self.href,
        this.textContentType
      ).subscribe(() => {
        this.snackbar.openFromComponent(NotificationComponent, {
          duration: 5000,
          data: {
            title: 'Änderung erfolgreich',
            message: 'Mitarbeiter wurde erfolgreich angelegt',
            type: 'info'
          }
        });
        this.reloadEmployees();
      }, error => {
        this.snackbar.openFromComponent(NotificationComponent, {
          duration: 5000,
          data: {
            title: 'Mitarbeiter konnte nicht angelegt werden',
            message: error.message,
            type: 'error'
          }
        });
      });
    }, error => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Mitarbeiter konnte nicht angelegt werden',
          message: error.message,
          type: 'error'
        }
      });
    });
  }

  updateEmployee(oldData: Employee, newData: Employee) {
    this.http.put(oldData._links.self.href, newData).subscribe(() => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Änderung erfolgreich',
          message: 'Mitarbeiter wurde erfolgreich geändert',
          type: 'info'
        }
      });
      this.reloadEmployees();
    }, error => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Mitarbeiter konnte nicht geändert werden',
          message: error.message,
          type: 'error'
        }
      });
    });
  }

  deleteEmployee(employee: Employee) {
    this.http.delete(employee._links.self.href).subscribe(() => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Änderung erfolgreich',
          message: 'Mitarbeiter wurde erfolgreich gelöscht',
          type: 'info'
        }
      });
      this.reloadEmployees();
    }, error => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Fehler beim Löschen des Mitarbeiters',
          message: error.message,
          type: 'error'
        }
      });
    });
  }
}
