import { Shift } from './../../../../_models/shift';
import { Injectable } from '@angular/core';
import { GenericDataService } from 'src/app/internalServices/genericDataService';


@Injectable({
  providedIn: 'root'
})
export class ShiftDataExchangeService extends GenericDataService<Shift> {

}
