import { GlobalConstants } from './../../global/global-constants';
import { NotificationComponent } from './../../helper-modules/notification/notification.component';
import { MatSnackBar } from '@angular/material';
import { RosteruserService } from './rosteruser.service';
import { Shift } from './../../_models/shift';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {
  textContentType = {
    headers: new HttpHeaders({ 'Content-Type': 'text/uri-list' })
  };

  shifts = new Subject<Shift[]>();
  shiftsForGroup = new Subject<Map<string, Shift[]>>();

  constructor(
    private http: HttpClient,
    private rosteruserService: RosteruserService,
    private snackbar: MatSnackBar) { }

  getShifts(): Observable<Shift[]> {
    return this.shifts.asObservable();
  }

  getShiftsForShiftgroup(): Observable<Map<string, Shift[]>> {
    return this.shiftsForGroup.asObservable();
  }

  reloadShifts() {
    const shiftsLink = this.rosteruserService.getCurrentUser()._links.shifts.href;
    return this.http.get(shiftsLink).subscribe((response: any) => {
      const shifts: Shift[] = response._embedded.shifts;
      const shiftsForGroup: Map<string, Shift[]> = new Map();
      shifts.forEach((shift) => {
        if (shiftsForGroup[shift.shiftgroup]) {
          shiftsForGroup[shift.shiftgroup].push(shift);
        } else {
          shiftsForGroup[shift.shiftgroup] = [shift];
        }
      });
      this.shifts.next(shifts);
      this.shiftsForGroup.next(shiftsForGroup);

      this.shifts.next(shifts);
    }, error => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Schichten konnten nicht geladen werden',
          message: error.message,
          type: 'error'
        }
      });
      this.shifts.next([]);
    });
  }

  createShift(shift: Shift) {
    this.http.post(GlobalConstants.apiURL + '/shifts', shift).subscribe((response: any) => {
      return this.http.put(
        response._links.rosterUser.href,
        this.rosteruserService.currentUser._links.self.href,
        this.textContentType
      ).subscribe(() => {
        this.snackbar.openFromComponent(NotificationComponent, {
          duration: 5000,
          data: {
            title: 'Änderung erfolgreich',
            message: 'Schicht wurde erfolgreich angelegt',
            type: 'info'
          }
        });
        this.reloadShifts();
      }, error => {
        this.snackbar.openFromComponent(NotificationComponent, {
          duration: 5000,
          data: {
            title: 'Schicht konnte nicht angelegt werden',
            message: error.message,
            type: 'error'
          }
        });
      });
    }, error => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Schicht konnte nicht angelegt werden',
          message: error.message,
          type: 'error'
        }
      });
    });
  }

  updateShift(oldData: Shift, newData: Shift) {
    this.http.put(oldData._links.self.href, newData).subscribe(() => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Änderung erfolgreich',
          message: 'Schicht wurde erfolgreich geändert',
          type: 'info'
        }
      });
      this.reloadShifts();
    }, error => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Schicht konnte nicht geändert werden',
          message: error.message,
          type: 'error'
        }
      });
    });
  }

  deleteShift(shift: Shift) {
    this.http.delete(shift._links.self.href).subscribe(() => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Änderung erfolgreich',
          message: 'Schicht wurde erfolgreich gelöscht',
          type: 'info'
        }
      });
      this.reloadShifts();
    }, error => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Fehler beim Löschen der Schicht',
          message: error.message,
          type: 'error'
        }
      });
    });
  }
}
