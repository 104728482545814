import { MonthdataService } from './../../../../_services/modules/monthdata.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CalendarDateFormatter, CalendarEvent, CalendarView } from 'angular-calendar';
import { Subject, Subscription } from 'rxjs';
import { NotificationComponent } from './../../../../helper-modules/notification/notification.component';
import { Employee } from './../../../../_models/employee';
import { Shift } from './../../../../_models/shift';
import { CustomDateFormatter } from './../../../../_services/helper/custom-date-formatter.provider';
import { EmployeeService } from './../../../../_services/modules/employee.service';
import { AvailDialogComponent } from './../avail-dialog/avail-dialog.component';

@Component({
  selector: 'app-avail-calendar',
  templateUrl: './avail-calendar.component.html',
  styleUrls: ['./avail-calendar.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class AvailCalendarComponent implements OnInit, OnDestroy {
  locale = 'de';

  subscriptions: Subscription[] = [];

  isVacation = false;
  isDateRange = false;
  startDate: Date;
  endDate: Date;

  employees: Employee[];
  chosenEmp: Employee;

  view: CalendarView = CalendarView.Month;
  currentDate: Date = new Date();
  events: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();

  clickedDate: Date;
  clickedColumn: number;
  showList = false;

  dragToCreateActive = false;
  weekStartsOn = 0;

  constructor(
    private dialog: MatDialog,
    private monthdataService: MonthdataService,
    private empService: EmployeeService,
    private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.subscriptions.push(
      this.monthdataService.getCurrentAvailEvents().subscribe(nonavailsAndVacations => {
        if (this.chosenEmp) {
          this.updateCalendar(nonavailsAndVacations.nonavailsForDay, nonavailsAndVacations.vacationsForDay);
        }
      }),
      this.empService.getEmployees().subscribe(emps => {
        this.employees = emps;
      })
    );
    this.empService.reloadEmployees();

    const yearmonth = (1 + this.currentDate.getMonth()) < 10 ?
      this.currentDate.getFullYear() + '-0' + (1 + this.currentDate.getMonth()) :
      this.currentDate.getFullYear() + '-' + (1 + this.currentDate.getMonth());
    this.monthdataService.setCurrentMonthData(yearmonth);
  }

  calendarClicked(date: Date) {
    if (!this.chosenEmp) {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Kein Mitarbeiter ausgewählt',
          message: 'Für das Eintragen von Verfügbarkeiten muss ein Mitarbeiter ausgewählt werden',
          type: 'error'
        }
      });
      return;
    }
    if (this.currentDate.getMonth() !== date.getMonth()) {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Ungültige Eingabe',
          message: 'Nur der aktuell eingestellte Monat kann bearbeitet werden',
          type: 'error'
        }
      });
      return;
    }

    if (this.isDateRange) {
      if (!this.startDate || (this.startDate && this.endDate) || (this.startDate && this.startDate.getDate() > date.getDate())) {
        this.startDate = date;
        this.endDate = null;
        return;
      } else {
        this.endDate = date;
      }
    } else {
      this.startDate = date;
    }

    if (this.isVacation) {
      const avails = this.monthdataService.getCurrentNonAvailsShiftsForDay();
      if (avails[date.getDate()]) {
        this.snackbar.openFromComponent(NotificationComponent, {
          duration: 5000,
          data: {
            title: 'Urlaub kann nicht hinzugefügt werden',
            message: 'Am gewählten Datum ist bereits eine Verfügbarkeit eingetragen',
            type: 'error'
          }
        });
        return;
      }
      this.switchVacation(date);
    } else {
      const vacations = this.monthdataService.getCurrentVacationsForDay();
      // TODO current vacationsForDay as observable to avoid async problems
      if (vacations[date.getDate()] && !this.isDateRange) {
        this.startDate = null;
        this.endDate = null;
        this.snackbar.openFromComponent(NotificationComponent, {
          duration: 5000,
          data: {
            title: 'Es kann keine Verfügbarkeit hinzugefügt werden',
            message: 'Am gewählten Datum ist bereits ein Urlaubstag eingetragen',
            type: 'error'
          }
        });
        return;
      }
      // this.openAvailDialog(date);
    }
  }

  switchVacation(date: Date) {
    this.monthdataService.switchVacation(date);
  }

  monthChanged() {
    this.events = [];
    this.chosenEmp = null;
    this.monthdataService.setCurrentAvailEmpId(null);
    const yearmonth = (1 + this.currentDate.getMonth()) < 10 ?
      this.currentDate.getFullYear() + '-0' + (1 + this.currentDate.getMonth()) :
      this.currentDate.getFullYear() + '-' + (1 + this.currentDate.getMonth());
    this.monthdataService.setCurrentMonthData(yearmonth);
  }

  empChanged() {
    this.monthdataService.setCurrentAvailEmpId(this.chosenEmp);
  }

  changeIsVacation(checked: boolean) {
    this.isVacation = checked;
    this.isDateRange = false;
  }

  changeDateRange(checked: boolean) {
    this.startDate = null;
    this.endDate = null;
    this.isDateRange = checked;
  }

  updateCalendar(nonAvails, vacations) {
    this.events = [];
    Object.keys(nonAvails).forEach(key => {
      nonAvails[key].forEach((shift: Shift) => {
        this.events.push({
          title: 'Keine ' + shift.notation,
          allDay: true,
          start: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), +key, 12),
          color: {
            primary: shift.color,
            secondary: shift.color
          }
        });
      });
    });

    Object.keys(vacations).forEach(key => {
      this.events.push({
        title: 'Urlaub',
        allDay: true,
        start: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), +key, 12),
        color: {
          primary: '#000000',
          secondary: '#ffffff'
        }
      });
    });

    this.refresh.next();
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }
}
