import { Employee } from './../../_models/employee';
import { NotificationComponent } from './../../helper-modules/notification/notification.component';
import { EmpSummary } from './../../_models/empsummary';
import { Vacation } from './../../_models/vacation';
import { DayShift } from './../../_models/dayshift';
import { Subject, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmpsumService {

  textContentType = {
    headers: new HttpHeaders({ 'Content-Type': 'text/uri-list' })
  };

  currentEmpsum: EmpSummary;
  currentEmployee = new Subject<Employee>();
  dayshiftsForShiftTerm = new Subject<{ [term: string]: DayShift[] }>();
  vacations = new Subject<Vacation[]>();

  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar) { }

  getDayshiftsForShiftTerm(): Observable<{ [term: string]: DayShift[] }> {
    return this.dayshiftsForShiftTerm.asObservable();
  }

  getCurrentEmployee(): Observable<Employee> {
    return this.currentEmployee.asObservable();
  }

  getVacations(): Observable<Vacation[]> {
    return this.vacations.asObservable();
  }

  reloadDataForEmpsum(empsum: EmpSummary) {
    this.currentEmpsum = empsum;

    this.http.get<any>(empsum._links.employee.href).subscribe(emp => {
      this.currentEmployee.next(emp);
    });

    this.http.get<any>(empsum._links.dayShifts.href).subscribe(dayshifts => {
      const dayshiftsEmbedded: DayShift[] = dayshifts._embedded.dayshifts;
      const dayShiftsSorted: DayShift[] = dayshiftsEmbedded.sort((ds1, ds2) => {
        return ds1.day - ds2.day;
      });

      const dayshiftsForShiftTerm: { [term: string]: DayShift[] } = {};
      dayShiftsSorted.forEach(ds => {
        if (dayshiftsForShiftTerm[ds.term]) {
          dayshiftsForShiftTerm[ds.term].push(ds);
        } else {
          dayshiftsForShiftTerm[ds.term] = [ds];
        }
      });

      this.dayshiftsForShiftTerm.next(dayshiftsForShiftTerm);
    });

    this.http.get<any>(empsum._links.vacationsList.href).subscribe(vacations => {
      this.vacations.next(vacations._embedded.vacations.sort((vac1, vac2) => {
        return vac1.day - vac2.day;
      }));
    });
  }

  updateDayshift(dayshift: DayShift) {
    this.http.put(dayshift._links.self.href, dayshift).subscribe(() => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Änderung erfolgreich',
          message: 'Schicht wurde erfolgreich angepasst',
          type: 'info'
        }
      });
      this.reloadDataForEmpsum(this.currentEmpsum);
    }, error => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Fehler beim Anpassen der Schicht',
          message: error.message,
          type: 'error'
        }
      });
    });
  }

  deleteDayshift(dayshift: DayShift) {
    this.http.delete(dayshift._links.self.href).subscribe(() => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Änderung erfolgreich',
          message: 'Schicht wurde erfolgreich entfernt',
          type: 'info'
        }
      });
      this.reloadDataForEmpsum(this.currentEmpsum);
    }, error => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Fehler beim Entfernen der Schicht',
          message: error.message,
          type: 'error'
        }
      });
    });
  }

  deleteVacation(vacation: Vacation) {
    this.http.delete(vacation._links.self.href).subscribe(() => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Änderung erfolgreich',
          message: 'Urlaubstag wurde erfolgreich entfernt',
          type: 'info'
        }
      });
      this.reloadDataForEmpsum(this.currentEmpsum);
    }, error => {
      this.snackbar.openFromComponent(NotificationComponent, {
        duration: 5000,
        data: {
          title: 'Fehler beim Entfernen des Urlaubstags',
          message: error.message,
          type: 'error'
        }
      });
    });
  }
}
