import { FormBuilder, FormGroup } from '@angular/forms';
import { RosteruserService } from './_services/modules/rosteruser.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Angular';
  loggedIn = false;
  loginForm: FormGroup;
  loginInfo: { email: string, password: string } = { email: '', password: '' };

  constructor(private rosteruserService: RosteruserService, private formBuilder: FormBuilder) {
    this.loginForm = this.formBuilder.group(this.loginInfo);
  }

  login(loginInfo: { email: string, password: string }) {
    this.rosteruserService.setCurrentUser(loginInfo.email, loginInfo.password).then(success => {
      if (success) {
        this.loggedIn = true;
      }
    });
  }
}
