import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit {
  title: string;
  message: string;

  // valid types: info, warning, error
  type: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.title = data.title;
    this.message = data.message;
    this.type = data.type;
  }

  ngOnInit() {
  }

}
